import { Directive, Input, ElementRef, OnChanges, HostListener, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[NumberValidator]'
})

export class NumberValidatorDirective implements OnChanges {
    @Input() inputValidator: ElementRef;

    constructor(private _el: ElementRef, private control: NgControl) { }

    @HostListener('input')
    ngOnChanges() {
        const pattern = /^[0-9]*$/; // /^\d+$/;
        if (!pattern.test(this._el.nativeElement.value)) {
            const inputValue = this._el.nativeElement.value.replace(/[^0-9]+/g, '');
            this.control.control.setValue(inputValue);
            this._el.nativeElement.value = inputValue;
        }
    }

    //  @HostListener('input', ['$event']) onInputChange(event) {
    //         const initalValue = this._el.nativeElement.value;
    //         this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    //      console.log(this.control.control);

    //      if (initalValue !== this._el.nativeElement.value) {
    //          event.stopPropagation();
    //      }
    //  }
}
