import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isBoolean'
})
export class IsBooleanPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (isNaN(value)) { return '-'; }
    return value === 0 ? 'No' : 'Yes';
  }

}
