import { Directive, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';
import { NgControl, FormGroup } from '@angular/forms';
import { CampaignFormService } from 'src/app/campaign/services/campaign-form.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[elementExist]'
})
export class ElementExistDirective implements OnDestroy {

  @Input() elementExist: ElementRef;

  constructor(
    private el: ElementRef,
    private ngControl: NgControl,
    private campaignFormService: CampaignFormService) { }

  ngOnDestroy() {
    this.campaignFormService.
      removeDynamicControl(this.el.nativeElement.getAttribute('formControlName'), this.ngControl);
  }

}
