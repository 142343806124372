import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
    selector: '[scroller]',
})
export class ScrollerDirective implements OnInit, OnDestroy {
    topPosToStartShowing = 300;
    isShow: boolean;
    scroll: number;
    srcElement: HTMLElement;
    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.scroll = 0;
        window.addEventListener('scroll', this.scrollEvent, true);
    }

    reset() {
        this.srcElement.scrollTop = this.scroll;
    }

    scrollEvent = (event: any): void => {
        this.isShow = false;
        const srcElement = event.srcElement;
        if (srcElement.className !== 'pages-content') {
            return;
        }
        this.srcElement = srcElement;
        const scrollPosition = this.srcElement.scrollTop;
        if (scrollPosition >= this.topPosToStartShowing) {
            this.isShow = true;
        }

        this.el.nativeElement.style.display = this.isShow ? 'block' : 'none';

    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.scrollEvent, true);
    }

}
