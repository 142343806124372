import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeToTitleCase'
})
export class SnakeToTitleCasePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.toLowerCase().split('_').map((word) => {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }

}
