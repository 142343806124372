import { Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResult } from 'src/app/core/models/api-result';
import { User } from 'src/app/customer/models/user.model';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { TokenStorage } from 'src/app/core/services/authentication/token-storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { SlideUpDownAnimation } from 'src/app/core/animation/collapse-animate';
import { environment } from 'src/environments/environment';
import { RegisterService } from './services/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [SlideUpDownAnimation]
})
export class RegisterComponent implements OnInit, OnDestroy {

  isValidForm: boolean;
  user: User;
  animationState = 'down';
  error: [];
  subscriptions: Subscription[] = [];
  response = {
    next: (x: ApiResult) => this.handleResult(x),
    error: err => this.handleError(err),
    complete: () => this.complete(),
  };
  isRegister = 0;
  constructor(
    @Inject(LOCALE_ID) private local: string,
    private router: Router,
    private registerService: RegisterService,
    private userService: UserService,
    private tokenStorage: TokenStorage,
    private authService: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.user = new User();
    if (this.registerService.getRegisterData() !== null) {
      this.user = this.registerService.getUserInfo();
    }
  }

  onSubmit(data: User) {
    const isRegisterOrUpdate = this.user.isRegister;
    // console.log('onSubmit', data);

    const allowedMethods = [0, 201]; // 0 add user, 201 update user
    if (allowedMethods.indexOf(isRegisterOrUpdate) === -1) {
      this.authService.alertToUser('invalid request', 'red-snackbar');
      this.router.navigate(['/login']);
      // console.log('invalid request');
      return;
    }

    data['request_source'] = isRegisterOrUpdate;
    data['otp'] = +this.user.otp;
    // console.log(data);

    this.registerCustomer(data);
  }

  registerCustomer(data: User) {
    this.subscriptions.push(this.userService.registerCustomer(data).subscribe(this.response));
  }

  onFormState(formState: boolean) {
    this.isValidForm = formState;
  }

  ngOnDestroy() {
    this.registerService.isAllowRegister = false;
    this.registerService.clear();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHander($event) {
    if ($event.currentTarget.performance.navigation.type === 1) {
      this.registerService.getRegisterData();
    } else {
      this.registerService.clear();
    }
  }

  handleResult(result: ApiResult) {
    if (!result || !result.data) {
      // console.log('data missing.');
      this.router.navigate(['/login']);
      return;
    }

    if (result.data['statusCode'] === 100 && result.data.userData) {
      this.tokenStorage.setAccessToken(result.data.userData).setUserActions(result.data.userData).setDefaultPath(result.data.userData);
      this.authService.setCurrentUser();
      let returnUrl = '/dashboard/index';
      if ((returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')) !== null) {
        localStorage.setItem(environment.returnUrl, returnUrl);
      } else {
        returnUrl = this.tokenStorage.getDefaultPath();
      }
      this.router.navigateByUrl(returnUrl);
      // console.log(result.data);
      return;
    }

    if (result.data['statusCode'] === 105 || result.data.statusCode === 102) {
      const message = result.message;
      this.error = { message, ...result.data };
      return;
    }
  }

  handleError(err) {
    // console.log(err);
    if (err.status === 422) {
      this.error = err.error.error.details;
    }
  }

  complete() {
    // console.log('complete');
  }
}

