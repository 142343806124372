import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { SafeHtmlPipe } from '../../../core/pipes/safe-html.pipe';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  providers: [SharedService, SafeHtmlPipe]
})
export class TermsAndConditionsComponent implements OnInit {
  pageData: any;
  constructor(private shareService: SharedService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'customer-class');
    const params = {
      "page_name": 'terms_and_conditions'
    };
    this.shareService.getStaticPageData(params).subscribe(res => {
      if (res) {
        this.pageData = res;
      }
      /* for (const field in res) {
        if (res[field]) {
          res[field].forEach(element => {
            if (element.series) {
                this.pageData[field] = res[field];
            }
          });
        }
      } */
    }, err => {
      // console.log(err);
    });
  }
}
