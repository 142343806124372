import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResult } from '../models/api-result';
import { AuthService } from './authentication/auth.service';
import { TokenStorage } from './authentication/token-storage.service';
import { IpService } from './ip.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private ipService: IpService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorage,
    private authService: AuthService) { }

  login(credentials): Observable<any> {
    return this.ipService.post('api/login', credentials).pipe(
      tap(res => {
        if (res.status === 'SUCCESS' && res.statusCode === 200) {
          this.tokenStorage.setAccessToken(res.data.userData).setUserActions(res.data.userData).setDefaultPath(res.data.userData);
          this.authService.setCurrentUser();
        }
      }),
      map(res => {
        if (res.status === 'SUCCESS' && res.statusCode === 200) {
          const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || res.data.userData.actions[0].defaultPath;
          localStorage.setItem(environment.returnUrl, returnUrl);
          return returnUrl;
        }
        return null;
      })
    );
  }

  signup(params): Observable<ApiResult> {
    return this.ipService.post('api/signup', params);
  }

  getUsers(filter): Observable<ApiResult> {
    return this.ipService.post('api/user/list', filter);
  }

  addUsers(params): Observable<ApiResult> {
    return this.ipService.post('api/user', params);
  }

  editUsers(params): Observable<ApiResult> {
    return this.ipService.put('api/user', params);
  }

  deleteUser(params): Observable<ApiResult> {
    return this.ipService.delete('api/user', params);
  }

  getUserByID(params): Observable<ApiResult> {
    return this.ipService.get('api/user', params);
  }

  addCustomer(params): Observable<ApiResult> {
    return this.ipService.post('api/customer', params);
  }

  updateCustomer(params): Observable<ApiResult> {
    return this.ipService.put('api/customer', params);
  }

  requestOtp(params): Observable<ApiResult> {
    return this.ipService.post('api/requestOtp', params);
  }

  reSendOtp(params): Observable<ApiResult> {
    return this.ipService.post('api/reSendOtp', params);
  }

  // temp service fake api
  getOtp(params): Observable<ApiResult> {
    return this.ipService.post('api/getOtp', params);
  }

  verifyOtp(params): Observable<ApiResult> {
    return this.ipService.post('api/verifyOtp', params);
  }

  registerCustomer(params): Observable<ApiResult> {
    return this.ipService.post('api/register', params);
  }

  verifyOtpSkipAction(params): Observable<ApiResult> {
    return this.ipService.post('api/verifyOtpSkipAction', params);
  }
}
