import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from 'util';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return (isNaN(value) || isNull(value)) ? value : parseFloat(value).toLocaleString();
  }

}
