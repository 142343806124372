import { Injectable } from '@angular/core';
import { IpService } from 'src/app/core/services/ip.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserLogsService {

  constructor(private ipService: IpService,private authService:AuthService) { }

  userLogs(params): Observable<any> {
    // console.log(params);
    return this.ipService.post('api/merchant-users/getLoginLogs', params) 
    .pipe(
      map( res => {
        if (!res || !res.data) {
          this.authService.alertToUser('Something went wrong while login logs', 'red-snackbar');
        } else {
          return res.data['login_logs'];
        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
      })
    );;
  }
}
