import { Location, LocationStrategy } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { TokenStorage } from 'src/app/core/services/authentication/token-storage.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pages-top',
  templateUrl: './pages-top.component.html',
  styleUrls: ['./pages-top.component.scss']
})
export class PagesTopComponent implements OnInit, OnDestroy, AfterViewChecked {

  avatarImgSrc: string = 'assets/images/avatar.png';
  userName: string = 'Folisise Chosielie';
  userPost: string = 'Musician, Player';

  defaultRout = 'index';
  sidebarToggle: boolean = true;
  tip = { ring: true, email: true };
  homeShow: boolean = false;
  arrowShow: boolean = false;
  subscriptions: Subscription[] = [];
  routeSubscription: Subscription;
  backStore: any;
  localDefaultPath: string;
  prevUrl: string;
  constructor(
    private _globalService: GlobalService,
    private location: Location,
    private router: Router,
    private url: LocationStrategy,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private authService: AuthService,
    public loader: LoadingBarService
  ) {
    // page progress bar percentage
    /*  this.router.events.subscribe(event => {
       if (event instanceof NavigationStart) {
         // set page progress bar loading to start on NavigationStart event router
         this.loader.start();
       }
       if (event instanceof RouteConfigLoadStart) {
         this.loader.increment(35);
       }
       if (event instanceof RouteConfigLoadEnd) {
         this.loader.increment(75);
       }
       if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
         // set page progress bar loading to end on NavigationEnd event router
         this.loader.complete();
       }
     }); */
  }

  redeemHome() {
    this.prevUrl = localStorage.getItem('prevUrl');
    if (this.backStore === true) {
      this.backStore = false;
      this.customerService.backStoreChanged(this.backStore);
    } else if (this.authService.loggedIn && this.prevUrl === '/login') {
      this.router.navigate(['/dashboard/index']);
      localStorage.removeItem('prevUrl');
    } else {
      this.location.back();
      localStorage.removeItem('storeLocation');
      this.arrowShow = false;
      this.renderer.removeClass(this.document.body, 'no-footer');
    }
  }

  ngOnInit() {
    const allowPaths = this.allowPaths();
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        if (event.url === '/dashboard/my-crocs-journey') {
          this.homeShow = false;
          this.renderer.addClass(this.document.body, 'no-footer');
        } else {
          this.homeShow = false;
          this.renderer.removeClass(this.document.body, 'no-footer');
        }

        if (allowPaths.indexOf(event.url) !== -1) {
          this.arrowShow = true;
          this.renderer.addClass(this.document.body, 'no-footer');
        } else {
          this.arrowShow = false;
          this.renderer.removeClass(this.document.body, 'no-footer');
        }
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
      }
    },
      error => { this.routeSubscription.unsubscribe(); },
      () => {
        this.routeSubscription ? this.routeSubscription.unsubscribe() : null;
      }
    );

    this.subscriptions.push(this.routeSubscription);
    if ((this.localDefaultPath = this.tokenStorage.getDefaultPath())) {
      this.defaultRout = this.localDefaultPath;
    }
    if (this.url.path() === '/dashboard/my-crocs-journey') {
      this.homeShow = false;
      this.renderer.addClass(this.document.body, 'no-footer');
    } else {
      this.homeShow = false;
      this.renderer.removeClass(this.document.body, 'no-footer');
    }

    /* const allowPaths = [
      '/dashboard/voucher-details',
      '/dashboard/coupon-details',
      '/dashboard/change-mobile-number',
      '/dashboard/queries-crocs-club',
      '/dashboard/about-crocs',
      '/dashboard/terms-of-use',
      '/dashboard/privacy-policy',
      '/dashboard/contact-us',
      '/dashboard/my-crocs-journey',
      '/dashboard/store-locator/find-store',
      '/dashboard/change-home-branch'
    ]; */

    if (allowPaths.indexOf(this.url.path()) > -1) {
      this.arrowShow = true;
      this.renderer.addClass(this.document.body, 'no-footer');
    } else {
      this.arrowShow = false;
      this.renderer.removeClass(this.document.body, 'no-footer');
    }

    this.subscriptions.push(this.customerService.backStore.subscribe(response => {
      this.backStore = response;
    }));

    /* this.subscription = this.router.events.pipe(
      filter(event => (event instanceof NavigationEnd)),
    ).subscribe(
      (routeData: NavigationEnd) => {
        if (routeData.url === '/dashboard/my-crocs-journey') {
          this.homeShow = false;
          this.renderer.addClass(this.document.body, 'no-footer');
        } else {
          this.homeShow = false;
          this.renderer.removeClass(this.document.body, 'no-footer');
        }

        if (allowPaths.indexOf(routeData.url) !== -1) {
          this.arrowShow = true;
          this.renderer.addClass(this.document.body, 'no-footer');
        } else {
          this.arrowShow = false;
          this.renderer.removeClass(this.document.body, 'no-footer');
        }
      },
      error => { this.subscription.unsubscribe(); },
      () => {
        this.subscription ? this.subscription.unsubscribe() : null;
      }
    ); */
  }

  ngAfterViewChecked() {
    if (localStorage.getItem('storeLocation')) {
      this.arrowShow = localStorage.getItem('storeLocation') === 'true';
      if (this.arrowShow === this.arrowShow) {
        this.arrowShow = !this.arrowShow;
        this.cdRef.detectChanges();
      }
      // window.localStorage.removeItem('storeLocation');
    }
    if ((this.localDefaultPath = this.tokenStorage.getDefaultPath())) {
      this.defaultRout = this.localDefaultPath;
    }
  }

  public _sidebarToggle() {
    this._globalService.data$.subscribe(data => {
      if (data.ev === 'sidebarToggle') {
        this.sidebarToggle = data.value;
      }
    }, error => {
      // console.log('Error: ' + error);
    });
    this._globalService.dataBusChanged('sidebarToggle', !this.sidebarToggle);
  }

  allowPaths(): string[] {
    return [
      '/dashboard/voucher-details',
      '/dashboard/coupon-details',
      '/dashboard/change-mobile-number',
      '/dashboard/queries-crocs-club',
      '/dashboard/about-crocs',
      '/dashboard/terms-of-use',
      '/dashboard/privacy-policy',
      '/dashboard/contact-us',
      '/dashboard/my-crocs-journey',
      '/dashboard/store-locator/find-store',
      '/dashboard/change-home-branch'
    ];
  }
  ngOnDestroy() {
    this.defaultRout = 'index';
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

  }
}

