import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CancelMobileChangeRequestService } from './cancel-mobile-change-request.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-cancel-mobile-change-request',
  templateUrl: './cancel-mobile-change-request.component.html',
  styleUrls: ['./cancel-mobile-change-request.component.scss']
})
export class CancelMobileChangeRequestComponent implements OnInit, OnDestroy {
  id: any;
  idObject: any;
  message: string;
  subscriptions: Subscription[] = [];
  success: boolean;

  constructor(
    private route: ActivatedRoute,
    private cancelMobileChangeRequestService: CancelMobileChangeRequestService,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.queryParams['q'];

    try {
      var base64Url = this.id.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      this.idObject = JSON.parse(atob(base64));

      //cancel mobile number change request
      let sendData = {
        "id": this.idObject.mobileChangeId,
        "customer_loyalty_id": this.idObject.updated_by,
        "status": 3,
        "request_type": 2
      }

      this.subscriptions.push(this.cancelMobileChangeRequestService.changeRequestStatus(sendData).subscribe(
        result => {
          if (result.status == "SUCCESS") {
            this.message = "Mobile number change request has been cancelled.";
            this.success = true;
          } else {
            this.message = "The request could not be processed";
            this.success = false;
          }
        }, err => {
          this.message = "The request could not be processed";
          this.success = false;
          // console.log(err);
        }
      )
      );

    } catch (err) {
      this.message = "The request could not be processed";
      this.success = false;
      // console.log(err);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}


