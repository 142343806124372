import { Injectable } from '@angular/core';
import { Observable, Subject, of, forkJoin } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { ApiResult } from 'src/app/core/models/api-result';
import { IpService } from 'src/app/core/services/ip.service';
import { environment } from 'src/environments/environment';
import { CampaignDataSourceClass, City, State, Store, IStepper } from '../models/campaign.model';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private dataSource = new Subject<CampaignDataSourceClass>();
  data$ = this.dataSource.asObservable();
  tempCampaign = null;
  stepperData: IStepper = {};
  constructor(private ipService: IpService) { }

  dataBusChanged(ev, value) {
    this.dataSource.next({
      ev: ev,
      value: value
    });
  }

  getCampaign() {
    if (this.tempCampaign) {
      this.setCampaign({ campaign: this.tempCampaign });
      return this.tempCampaign;
    }

    const campaign: string = <string>localStorage.getItem(environment.campaign);
    this.tempCampaign = campaign;
    return campaign;
  }

  setCampaign(data: any) {
    if (data) {
      // console.log('setCampaign');
      this.tempCampaign = data;
      localStorage.setItem(environment.campaign, JSON.stringify(data));
    }
    return this;
  }

  decodeCampaign() {
    try {
      return JSON.parse(this.getCampaign());
    } catch (err) {
      this.destroyCampaign();
    }
  }

  destroyCampaign() {
    this.tempCampaign = null;
    localStorage.removeItem(environment.campaign);
  }

  getCampaignDetails(params: any): Observable<any> {
    return this.ipService.post('api/merchant-campaigns/get-campaign-details', params)
      .pipe(
        map(res => res.data),
        retry(3),
        catchError(error => {
          return of(error);
        }));
  }

  getCities(params: any): Observable<City[]> {
    return this.ipService.post('api/merchant-campaigns/get-cities', params).pipe(
      map((res: ApiResult) => res.data),
      catchError(_ => {
        return of([]);
      }));
  }

  getStores(params: any): Observable<Store[]> {
    return this.ipService.post('api/merchant-campaigns/get-city-stores', params).pipe(
      map((res: ApiResult) => res.data),
      catchError(_ => {
        return of([]);
      }));
  }

  getStates(params: any): Observable<State[]> {
    return this.ipService.post('api/merchant-users/get-states', params).pipe(
      map((res: ApiResult) => res.data),
      catchError(_ => {
        return of([]);
      })
    );
  }

  stepList(): string[] {
    return [
      'basicDetails',
      'audienceSelection',
      'rewardsDetails',
      'notifications',
      'couponDesign',
    ];
  }

  collectStepsServces() {
    const serviceCollection = [];
    const stepList = this.stepList();
    for (const step of stepList) {
      serviceCollection.push(this.getCampaignDetails({ step }));
    }
    return serviceCollection;
  }

  getStepperData(): Observable<IStepper[]> {
    return forkJoin(this.collectStepsServces());
  }
}
