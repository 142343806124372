import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'front-pages-top',
  templateUrl: './front-pages-top.component.html',
  styleUrls: ['./front-pages-top.component.scss']
})
export class FrontPagesTopComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, public authService: AuthService) { }

  ngOnInit() {
  }

  logout() {
    if (this.authService.currentUser && this.authService.currentUser.isCustomer) {
      this.renderer.removeClass(this.document.body, 'merchant-class');
    } else if (this.authService.currentUser && this.authService.currentUser.isMerchant) {
      this.renderer.removeClass(this.document.body, 'customer-class');
    }
    this.authService.logout();
  }

}
