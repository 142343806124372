import { Component, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/authentication/auth.service';
import { TokenStorage } from './core/services/authentication/token-storage.service';
import { Location } from '@angular/common';
import { SplashScreenService } from './core/services/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SplashScreenService]
})
export class AppComponent implements AfterViewInit {
  title = 'crocs';
  @ViewChild('splashScreen', { read: ElementRef })
  splashScreen: ElementRef;
  splashScreenImage: string;

  constructor(
    private auth: AuthService,
    router: Router,
    private tokenStorage: TokenStorage,
    private route: ActivatedRoute,
    private location: Location,
    private splashScreenService: SplashScreenService) {
    this.splashScreenImage = '../assets/images/crocs-logo-splash.png';
    if (!this.auth.loggedIn) { return; }

    if (this.auth.loggedIn) {
      this.tokenStorage.setRouteData(environment.currentNavigateRoute, this.location.path());
    }
    const returnUrl = localStorage.getItem(environment.returnUrl);
    const previousRoute = localStorage.getItem(environment.currentNavigateRoute);

    if (returnUrl) {
      localStorage.removeItem(environment.returnUrl);
      router.navigateByUrl(returnUrl);
    }

    if (previousRoute) {
      localStorage.removeItem(environment.currentNavigateRoute);
      router.navigateByUrl(previousRoute);
      return;
    }

    if (!returnUrl) {
      const defaultUrl = localStorage.getItem(environment.defaultPath);
      router.navigateByUrl(defaultUrl);
      return;
    }

  }

  // @HostListener('window:load', ['$event']) loadHandler(event: Event) {
  //   console.log('window:load', this.location.path());
  //   if (this.auth.loggedIn) {
  //     this.tokenStorage.setRouteData(environment.currentNavigateRoute, this.location.path());
  //   }
  // }

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    this.tokenStorage.getAccessToken();
    this.tokenStorage.getActions();
    this.tokenStorage.getDefaultPath();
  }

  ngAfterViewInit(): void {
    if (this.splashScreen) {
         this.splashScreenService.init(this.splashScreen.nativeElement);
    }
  }
}
