import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResult } from '../models/api-result';
import { IpService } from './ip.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  couponData: any;
  isJourneyStart;
  getViewCoupon: any;
  private _data = new BehaviorSubject(null);
  couponContent = this._data.asObservable();

  @Output() backStore = new EventEmitter();
  latLongData: string;
  isMapLoad: any;
  keywordValue: string;

  constructor(private ipService: IpService) { }
  
  getDashboardDetails(params?): Observable<any> {
    return this.ipService.post('api/customer/customer-dashboard-details', params);
  }

  updateCoupans(params): Observable<ApiResult> {
    return this.ipService.put('api/customer/update-coupans', params);
  }
  customerCrocsFeed(): Observable<any> {
    return this.ipService.post('api/customer/customer-crocs-feed', {});
  }
  customerCouponsVouchers(): Observable<any> {
    return this.ipService.post('api/customer/customer-coupons-vouchers', {});
  }
  getCities(): Observable<any> {
    return this.ipService.post('api/shared-controller/getAllCities', {});
  }
  getUnUsedReward(params): Observable<any> {
    return this.ipService.post('api/customer/customer-voucher-details', params);
  }
  generateShortUrl(): Observable<any> {
    return this.ipService.post('api/shared-controller/getCouponShortURL', {});
  }



  public setCouponData(data: any) {
    localStorage.setItem('couponData', JSON.stringify(data['couponData']));
    this._data.next(data);
  }

  public setLatlongData(data: any) {
    localStorage.setItem('latLongData', JSON.stringify(data['latLongData']));
    this._data.next(data);
  }

  public setIsMapLoad(data: any) {
    localStorage.setItem('isMapLoad', JSON.stringify(data));
    this._data.next(data);
  }

  public setSearchKeywordValue(data: any) {
    localStorage.setItem('setSearchKeywordValue', JSON.stringify(data['city']));
    this._data.next(data);
  }

  public getCouponData() {
    try {
      this.couponData = localStorage.getItem('couponData');
      return JSON.parse(this.couponData);
    } catch (err) {
      console.error('getCouponData', err);
    }
  }

  public getLatLongData() {
    try {
      this.latLongData = localStorage.getItem('latLongData');
      return JSON.parse(this.latLongData);
    } catch (err) {
      console.error('getLatLongData', err);
    }
  }

  public getIsMapLoad() {
    try {
      this.isMapLoad = localStorage.getItem('isMapLoad');
      return JSON.parse(this.isMapLoad);
    } catch (err) {
      console.error('getIsMapLoad', err);
    }
  }

  public getSearchKeywordValue() {
    try {
      this.keywordValue = localStorage.getItem('setSearchKeywordValue');
      return JSON.parse(this.keywordValue);
    } catch (err) {
      console.error('getSearchKeywordValue', err);
    }
  }

  clearcouponData() {
    localStorage.removeItem('couponData');
  }

  backStoreChanged(backStoredata) {
    this.backStore.emit(backStoredata);
  }


}
