import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { UserModel } from 'src/app/merchant/components/users/model/user.model';
import { UserListService } from 'src/app/merchant/components/users/users-list/user-list.service';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import Swal from 'sweetalert2';
import { CapsLettersPipe } from '../../../core/pipes/caps-letters.pipe';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface PeriodicElement {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: number;
  userName: string;
  gender;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  providers: [CapsLettersPipe]
})
export class UserEditComponent implements OnInit {
  merchantForm: FormGroup;
  displayedColumns: string[] = [
    'avatar',
    'first_name',
    'last_name',
    'user_type',
    'merchant_name',
    'sub_merchant_name',
    'location_name',
    'email',
    'contact',
    'gender',
    'status',
    'created_at',
    'actions'
  ];
  profileEdit: boolean = false;
  pipe = new DatePipe("en-IN"); // Use your own locale
  isMobileEnable = true;
  fromBtnText = 'Save';
  selection = new SelectionModel<UserModel>(true, []);
  userModel: UserModel;

  @Input('user') user: UserModel;
  @Input('updateProfile') updateProfile: boolean;
  @Input('isEdit') isEdit: boolean = false;
  @Output('submited') userSubmited = new EventEmitter<UserModel>();
  @Input('submitButton') submitButton: string;
  @Output() isSubmit = new EventEmitter<boolean>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  oldUserModel: UserModel;
  formControlArray: string[];
  panelState: Boolean = false;
  hide: boolean = true;
  confirmHide: boolean = true;
  user_type_id: number = 0;
  subMerchantsArray: any = [];
  subMerchantLocationArray: any = [];
  adminId: number;
  editValueId: any;
  isRequired: Boolean = true;
  merchantPlaceholderMsg: string;
  currentUserTypeId: number;
  userTypeArray: any;
  editPanel: boolean = false;
  isSubmitedCall: boolean;
  subMerchantId: number;

  filteredOptions: any = [];
  subMerchantLocationId: any;
  userNameValidate: boolean = true;
  isDisabled: boolean = false;
  msg: any;
  isSearchInProgress: boolean;
  searchData: { 'search_keyword': string; 'sub_merchant_id': any; };
  subscriptions: Subscription[] = [];
  subMerchantSelectedValue: any;
  locationValidate: boolean;
  notFound: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private userListService: UserListService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.locationValidate = false;
    if (!this.isEdit) {
      this.user = new UserModel();
      this.user.clear();
    }

    if (this.isEdit) {
      this.isRequired = false;
      this.isDisabled = true;
      this.userNameValidate = false;

    } else {
      this.isRequired = true;
      this.isDisabled = false;
    }

    this.userModel = this.user;
    this.editValueId = this.userModel.id;

    if (this.user.user_type_id > 3) {
      this.subMerchantSelection(this.user.sub_merchant_id);
    }
    this.merchantForm = this.createForm();
    if (this.editValueId) {

      if (this.user.user_type_id) {
        this.userTypeSelection(this.user.user_type_id);
      }
      this.merchantForm.get('username').disable();
      this.merchantForm.get('user_type_id').disable();
      this.merchantForm.get('sub_merchant_id').disable();

      const subMerchantLocationNameControl = this.merchantForm.get('sub_merchant_location_id');
      subMerchantLocationNameControl.setValue({ "id": this.user['sub_merchant_location_id'], "location_name": this.user['location_name'] });
      subMerchantLocationNameControl.updateValueAndValidity();

    }
    if (this.updateProfile) {
      this.isDisabled = true;
      this.userNameValidate = false;
      const currentUserValue = this.authService.currentUser.user_type_id;

      if (currentUserValue == 1 || currentUserValue == 2 || currentUserValue == 3) {
        const subMerchantLocationNameControl = this.merchantForm.get('sub_merchant_location_id');
        subMerchantLocationNameControl.setValue(null);
        subMerchantLocationNameControl.updateValueAndValidity();
      } else {
        const subMerchantLocationNameControl = this.merchantForm.get('sub_merchant_location_id');
        subMerchantLocationNameControl.setValue({ "id": this.user['sub_merchant_location_id'], "location_name": this.user['location_name'] });
        subMerchantLocationNameControl.updateValueAndValidity();
      }
    }

    this.currentUserTypeId = this.authService.currentUser.user_type_id;

    this.userListService.getUserTypes().subscribe(
      res => {
        this.userTypeArray = res.data['user_type_list'];
      },
      err => {
        // console.log(err);
      }
    );
    this.loadLocationList();

    if (this.currentUserTypeId == 4 && !this.isEdit) {
      this.subMerchantId = this.authService.currentUser.sub_merchant_id;
      this.user_type_id = 5;
      const subMerchantIdCOntrol = this.merchantForm.get('user_type_id');
      subMerchantIdCOntrol.setValue(5);

      const subMerchantLocationCOntrol = this.merchantForm.get('sub_merchant_location_id');
      subMerchantLocationCOntrol.enable();
      subMerchantIdCOntrol.updateValueAndValidity();
      subMerchantLocationCOntrol.updateValueAndValidity();
    }
    // this.setValidator(this.user_type_id);
  }

  displayFn(user?: any): any | undefined {
    if (typeof user == "object") {
      let locationName = user ? user.location_name : '';
      return locationName;
      // return locationName.replace(/\b\w{3,}/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()))
    }
  }

  ngOnChanges() {
  }

  createForm() {
    return this._formBuilder.group({
      first_name: [this.userModel.first_name, Validators.compose([Validators.pattern(/^[a-zA-Z]+$/)])],
      last_name: [this.userModel.last_name, Validators.compose([Validators.pattern(/^[a-zA-Z]+$/)])],
      username: [
        this.userModel.username,
        [
          Validators.compose([
            Validators.pattern(/^[ A-Za-z0-9_@!@#$&*.]*$/),
            Validators.minLength(5)
          ])
        ]
      ],
      contact: [
        this.userModel.contact,
        [
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[0-9](?!00000000)\d{7,14}$/),
            Validators.minLength(8),
            Validators.maxLength(15)
          ])
        ]
      ],
      password: [
        this.userModel.password,
        Validators.compose([Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\#\?\!\@\$\%\^\&\*\-\+\=\_\:\"\;\'\<\>\,\.\{\}\[\]\|\\]).{8,}$/),
        Validators.minLength(8),])
      ],
      email: [this.userModel.email, [Validators.compose([
        Validators.required,
        // Validators.pattern(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])]
      ],
      confirm_password: [this.userModel.confirm_password],
      gender: [this.userModel.gender, [Validators.required]],
      user_type_id: [this.userModel.user_type_id, [Validators.required]],
      // userProfileUpdate: [this.userModel.userProfileUpdate],
      sub_merchant_id: [{ value: this.userModel.sub_merchant_id, disabled: true }],
      sub_merchant_location_id: [{ value: this.userModel.sub_merchant_location_id, disabled: true }, [Validators.required]]
    },
    {
      validators: this.passwordMatching()
    });
  }

  passwordMatching() {
    return (formGroup: FormGroup): {
      [key: string]: any
    } => {
      const password = formGroup.get('password');
      const confirmPassword = formGroup.get('confirm_password');

      if (password.value !== confirmPassword.value) {
        const formControl = formGroup.get('confirm_password');
        if (formControl) {
          formControl.setErrors({
            serverError: true,
            message: 'Password doesn\'t match'
          });
        }
        return;
      }
    }
  }

  /* passValidation() {
    const passwordControl = this.merchantForm.get('password').value;
    const confirmPassControl = this.merchantForm.get('confirm_password').value;
    if (passwordControl !== confirmPassControl) {
      return false;
    } else {
      return true;
    }
  } */

  submitForm(formValues) {
    this.adminId = 1;
    const merchantId = this.authService.currentUser['merchant_id'];
    const params = this.merchantForm.getRawValue();
    if (this.currentUserTypeId == 4 || this.currentUserTypeId == 5) {
      params['sub_merchant_id'] = this.subMerchantId;
    }
    if (this.editValueId) {
      if (params['password'] === null) {
        params['password'] = '';
      }
      if (params['confirm_password'] === null) {
        params['confirm_password'] = '';
      }

      params['merchant_id'] = merchantId;
      params['id'] = this.editValueId;
      const currentUserValue = this.authService.currentUser.user_type_id;

      if (this.updateProfile) {
        if (currentUserValue == 1 || currentUserValue == 2 || currentUserValue == 3) {
          params['sub_merchant_location_id'] = null;
        } else {
          params['sub_merchant_location_id'] = this.subMerchantLocationId;
        }
      } else {
        params['sub_merchant_location_id'] = this.subMerchantLocationId;
      }

      if (params['sub_merchant_location_id'] === undefined) {
        const locationId = this.merchantForm.get('sub_merchant_location_id').value;
        params['sub_merchant_location_id'] = locationId.id;
      }
      this.userListService.updateUser(params).subscribe(
        res => {
          let message = '';
          if (this.isEdit && this.updateProfile) {
            message = 'User profile updated successfully';
          } else if (this.isEdit) {
            message = res['message'];
          }
          Swal.fire('Success', message, 'success');
          this.panelState = !this.panelState;
          this.isSubmitedCall = true;

          this.isSubmit.emit(this.isSubmitedCall);

          if (this.updateProfile && res) {
            this.router.navigate(['../../../merchant/index']);
          }
        },
        err => {
          // console.log(err);
        }
      );

    } else {
      params['merchant_id'] = merchantId;
      if (this.user_type_id == 2 || this.user_type_id == 3) {
        params['sub_merchant_location_id'] = null;
      } else {
        params['sub_merchant_location_id'] = this.subMerchantLocationId;
      }
      this.userListService.addUser(params).subscribe(
        res => {
          const message = res['message'];
          if (res.status === 'FAILURE' && res.statusCode == 200) {
            const formControl = this.merchantForm.get('username');
            if (formControl) {
              formControl.setErrors({
                serverError: true,
                message: 'Username already exist'
              });
            }
            this.userNameValidate = true;
          } else {
            this.panelState = !this.panelState;
            Swal.fire('Success', message, 'success');
            this.isSubmitedCall = true;
            this.isSubmit.emit(this.isSubmitedCall);
          }

        },
        error => {
          if (error.status === 422) {
            if (!error.error.error.details) {
            } else {
              const form = this.merchantForm;
              const serverErrors = error.error.error.details;
              Object.values(serverErrors).forEach(prop => {
                const formControl = form.get(prop['key']);
                if (formControl) {
                  formControl.setErrors({
                    serverError: true,
                    message: prop['message']
                  });
                }
              });
            }
          }
        }
      );
    }

  }

  checkUsername(username) {
    const params = {
      'username': username
    };
    this.userListService.checkUsername(params).subscribe(
      res => {
        if (res['status'] === 'FAILURE') {
          this.userNameValidate = true;

          const form = this.merchantForm;
          const serverErrors = res;

          this.merchantForm.get('username').setErrors({
            serverError: true,
            message: serverErrors['message']
          });
        } else if (res['status'] === 'SUCCESS') {
          this.userNameValidate = false;
          this.msg = res['message'];
        }
      },
      error => {
        if (error.status === 422) {
          const form = this.merchantForm;
          const serverErrors = error.error.error.details;
          Object.values(serverErrors).forEach(prop => {
            const formControl = form.get(prop['key']);
            if (formControl) {
              formControl.setErrors({
                serverError: true,
                message: prop['message']
              });
            }
          });
        }
        // console.log(error);
      }
    );
  }

  userTypeSelection(selectedValue) {
    this.user_type_id = selectedValue;
    const subMerchantIdControl = this.merchantForm.get('sub_merchant_id');
    const subMerchantLocationControl = this.merchantForm.get('sub_merchant_location_id');
    if (selectedValue == 4 || selectedValue == 5) {
      if (this.currentUserTypeId == 4 && selectedValue == 5) {
        subMerchantIdControl.setValue(null);
        subMerchantIdControl.disable();
        subMerchantIdControl.clearValidators();
      } else {
        subMerchantIdControl.enable();
        subMerchantIdControl.setValidators([Validators.required]);
      }

      subMerchantLocationControl.enable();
      subMerchantLocationControl.setValidators([Validators.required]);
      this.subMerchantId = this.authService.currentUser.sub_merchant_id;

      const id = { merchant_id: 1 };
      this.userListService.getSubMerchantDetails(id).subscribe(
        res => {
          this.subMerchantsArray = res.data['merchant_list'];
        },
        err => {
          // console.log(err);
        }
      );

    } else {
      subMerchantIdControl.setValue(null);
      subMerchantLocationControl.setValue(null);
      subMerchantIdControl.disable();
      subMerchantLocationControl.disable();
      subMerchantIdControl.clearValidators();
      subMerchantLocationControl.clearValidators();
    }
    subMerchantIdControl.updateValueAndValidity();
    subMerchantLocationControl.updateValueAndValidity();

  }

  subMerchantSelection(selectedValue) {
    if (!this.isEdit) {
      const subMerchantIdControl = this.merchantForm.get('sub_merchant_location_id');
      subMerchantIdControl.setValue('');
    }
    this.subMerchantSelectedValue = selectedValue;

  }

  selectLocation(value) {
    this.subMerchantLocationId = value.id;
    this.locationValidate = false;
  }

  removeExtraSpaces(value) {

    if (this.merchantForm.get(value)) {
      if (!(this.merchantForm.get(value).value == null)) {
        const commentControl = this.merchantForm.get(value);
        const value1 = commentControl.value;
        commentControl.setValue(value1.toString().replace(/\s\s+/g, ' ').trim());
      }
    }
  }

  loadLocationList() {
    if (this.currentUserTypeId == 4) {
      this.subMerchantSelectedValue = this.authService.currentUser.sub_merchant_id;
    }
    const subMerchantLocationControl = this.merchantForm.get('sub_merchant_location_id');
    this.subscriptions.push(subMerchantLocationControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((value) => {
          let locationValue = value ? value.location_name ? value.location_name : value : '';
          if (locationValue) {
            if (locationValue.length > 2) {
              return true;
            } else {
              this.locationValidate = true;
              this.notFound = false;
              return false;
            }
          } else {
            return false;
          }
        }),
        tap(() => (this.isSearchInProgress = true)),
        switchMap(value => {
          if (typeof value === 'string') {
            this.searchData = { 'search_keyword': value, 'sub_merchant_id': this.subMerchantSelectedValue };
            return this.userListService.getSubMerchantPosLocations(this.searchData).pipe(finalize(() => (this.isSearchInProgress = false)));
          }
          return of(value).pipe(finalize(() => (this.isSearchInProgress = false)));
        })
      ).subscribe(result => {
        if (typeof result === 'object') {
          this.filteredOptions = result.location_list;
          if (this.filteredOptions) {
            if (this.filteredOptions.length == 0) {
              this.locationValidate = true;
              this.notFound = true;
              // var element = <HTMLElement>document.querySelector('.cdk-overlay-container');
              // element.setAttribute('style', 'pointer-events: none!important');
            }
            else {
              this.locationValidate = true;
              this.notFound = false;
              // var element = <HTMLElement>document.querySelector('.cdk-overlay-container');
              // element.setAttribute('style', 'pointer-events:inherit!important');
            }
          }
        }
      })
    );

  }
}
