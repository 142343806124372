import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PortletModule } from './content/general/portlet/portlet.module';
import { SpinnerButtonModule } from './content/general/spinner-button/spinner-button.module';
import { NoticeComponent } from './content/general/notice/notice.component';

@NgModule({
  declarations: [NoticeComponent],
  imports: [
    CommonModule,
    PortletModule,
    SpinnerButtonModule
  ],
  exports: [
    PortletModule,
    SpinnerButtonModule,
    NoticeComponent
  ]
})
export class PartialsModule { }
