export const environment = {
  production: true,
  serverUrl: 'https://crocs.boomerup.com:8443',
  encriptionKey: 'crossSaas@sec#pass123',
  accessTokenParam: 'csAn',
  lsSecureKey: 'cross-saas',
  returnUrl: 'cRUl',
  userInfo: 'csUf',
  defaultPath: 'defaultPath',
  currentNavigateRoute: 'cNt',
  campaign: 'campaign'
};
