import { Injectable } from '@angular/core';
import { IpService } from 'src/app/core/services/ip.service';

@Injectable({
  providedIn: 'root'
})
export class OptOutService {

  constructor(private ipService: IpService) { }

  optOut(params) {
    return this.ipService.post('api/shared-controller/optOut', params);
  }
  optIn(params) {
    return this.ipService.post('api/shared-controller/optIn', params);
  }
}
