import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { MatDialogRef } from '@angular/material/dialog';
// import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-terms-and-conditions-popup',
  templateUrl: './terms-and-conditions-popup.component.html',
  styleUrls: ['./terms-and-conditions-popup.component.scss'],
  providers: [SharedService]
})
export class TermsAndConditionsPopupComponent implements OnInit {

  pageData: any;
  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsPopupComponent>,
    private shareService: SharedService
  ) { }

  ngOnInit() {
    const params = {
      "page_name": 'terms_and_conditions'
    }
    this.shareService.getStaticPageData(params).subscribe(res => {
      if (res) {
        this.pageData = res;
      }
    }, err => {
      // console.log(err);
    });
  }

  togglePanelSearch() {
    this.dialogRef.close();
  }

}
