import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { GlobalService } from '../../services/global.service';
import { Router, NavigationEnd } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [MenuService]
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewChecked {

  public menuInfo: Array<any> = [];
  public sidebarToggle = true;
  showSidebar: boolean;
  subscription: any;

  constructor(
    public _menuService: MenuService,
    public _globalService: GlobalService,
    private router: Router,
    private url: LocationStrategy,
    private cdRef: ChangeDetectorRef,
    private authService: AuthService,
  ) { }


  ngOnInit() {
    this.showSidebar = true;

    this.menuInfo = this._menuService.putSidebarJson();
    this._sidebarToggle();
    this._menuService.selectItem(this.menuInfo);
    this._isSelectItem(this.menuInfo);

    const allowPaths = ['/dashboard/my-crocs-journey', '/dashboard/voucher-details', '/dashboard/coupon-details', '/dashboard/change-mobile-number', '/dashboard/queries-crocs-club', '/dashboard/about-crocs', '/dashboard/terms-of-use', '/dashboard/privacy-policy', '/dashboard/contact-us'];
    if (allowPaths.indexOf(this.url.path()) > -1) {
      this.showSidebar = false;
    } else {
      this.showSidebar = true;
    }

    this.subscription = this.router.events.pipe(
      filter(event => (event instanceof NavigationEnd)),
    ).subscribe(
      (routeData: NavigationEnd) => {
        if (allowPaths.indexOf(routeData.url) !== -1) {
          this.showSidebar = false;
        } else {
          this.showSidebar = true;
        }
        if (localStorage.getItem('storeLocation')) {
          this.showSidebar = localStorage.getItem('storeLocation') === 'true';
        }

      },
      error => { this.subscription.unsubscribe(); },
      () => {
        // console.log('complete');

        this.subscription.unsubscribe();
      }
    );


  }

  ngAfterViewChecked() {
    if (localStorage.getItem('storeLocation')) {
      this.showSidebar = localStorage.getItem('storeLocation') === 'true';
      if (this.showSidebar == this.showSidebar) {
        this.showSidebar = this.showSidebar;
        this.cdRef.detectChanges();
      }
    }
  }

  public _sidebarToggle() {
    this._globalService.data$.subscribe(data => {
      if (data.ev === 'sidebarToggle') {
        this.sidebarToggle = data.value;
      }
    }, error => {
      console.log('Error: ' + error);
    });

  }

  _isSelectItem(item) {
    for (const i in item) {
      if (item[i].children) {
        for (const index in item[i].children) {
          if (item[i].children[index].isActive || item[i].children[index].toggle === 'on') {
            item[i].toggle = 'on';
            break;
          } else {
            this._isSelectItem(item[i].children);
          }
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
