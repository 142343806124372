import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { UserLogsService } from './user-logs.service';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.scss']
})
export class UserLogsComponent implements OnInit {
  firstName: any;
  lastName: any;
  dataSource: any;
  pipe = new DatePipe("en-IN"); // Use your own locale
  displayedColumns: string[] = [
    // 'avatar',
    'loginTime',
    'last_access_time',
    'out_time'
  ];
  constructor(public dialogRef: MatDialogRef<UserLogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOCALE_ID) private local: string,
    public dialog: MatDialog, private authService: AuthService, private userLogService: UserLogsService) { }

  ngOnInit() {
    if (this.data) {
      this.firstName = this.data["first_name"];
      this.lastName = this.data["last_name"];
    }
    let userLogsId = {
      "id": this.data["id"]
    };
       
    this.userLogService.userLogs(userLogsId).subscribe(res => {
      
        this.dataSource = res;
      
      
    }, err => {
      // console.log(err);
    });

  }
  transformDate(date: Date = new Date(), dateFormat: string = 'dd MMM yyyy hh:mm a') {
    return this.pipe.transform(date, dateFormat, this.local);
  }
  closeDialog() {
    this.dialogRef.close();
  }


}
