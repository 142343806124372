import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';

@Pipe({
  name: 'common'
})
export class CommonPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string) { }

  transform(value: any, pipe: string, fnArgument?: any): any {
    if (value === '' || !pipe) {
      return null;
    }
    // tslint:disable-next-line: radix
    value = parseInt(value);
    switch (pipe) {
      case 'isActive':
        if (isNaN(value)) { return '-'; }
        return (value === 0) ? 'Inactive' : 'Active';
      case 'isBoolean':
        if (isNaN(value)) { return '-'; }
        return (value === 0) ? 'No' : 'Yes';
      case 'checkGender':
        return (value === 1) ? 'Male' : (value === 2) ? 'Female' : '-';
      case 'platform':
        return (value === 1) ? 'POS' : (value === 2) ? 'Customer Portal' : (value === 3) ? 'API' : '-';
      case 'maritalStatus':
        return (value === 1) ? 'Single' : (value === 2) ? 'Married' : '-';
      case 'salesType':
          return (value === 0) ? 'Fresh' : (value === 1) ? 'Onetimer' : (value === 2) ? 'Repeater' : '-';
      case 'customerStatus':
        return (value === 0) ? 'Inactive' : (value === 1) ? 'Active' : (value === 3) ? 'Blocked' : '-';
      case 'billFrom':
        return (value === 1) ? 'FTP' : (value === 3) ? 'API' : '-';
      case 'redemptionFrom':
        return (value === 1) ? 'FTP' : (value === 2) ? 'API' : '-';
      default:
        return 'Unknown Pipe';
    }
  }

}
