import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ScrollerDirective } from 'src/app/core/directives/scroller.directive';

@Component({
  selector: 'p-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {

  @ViewChild(ScrollerDirective) scroller: ScrollerDirective;

  constructor() { }

  ngOnInit() {
  }

  gotoTop() {
    this.scroller.reset();
  }
}
