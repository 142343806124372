import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchCustomer, SearchCustomerRequest } from '../../../core/models/search-customer';
import { IpService } from '../../../core/services/ip.service';

@Injectable({
  providedIn: 'root'
})
export class MobileNumberChangeService {

  constructor(
    private ipService: IpService
  ) { }

  requestOtp(params): Observable<any> {
    return this.ipService.post('api/requestOtp', params);
  }

  getOtp(params): Observable<any> {
    return this.ipService.post('api/getOtp', params);
  }

  verifyOtp(params): Observable<any> {
    return this.ipService.post('api/verifyOtp', params);
  }

  getUsers(params): Observable<any> {
    return this.ipService.post('api/merchant-customers/customer-details', params);

  }

  insertMobileChangeLog(params): Observable<any> {
    return this.ipService.post('api/merchant-customers/insertMobileNumberChangeLog', params);
  }

  checkForPendingRequest(params: SearchCustomerRequest): Observable<SearchCustomer> {
    return this.ipService.post('api/merchant-customers/check-for-pending-request', params).pipe(
      map(result => result.data)
    );
  }

  changeRequestStatus(params) {
    return this.ipService.put('api/merchant-customers/changeRequestStatus', params);
  }
}
