import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private isDownloadRequest = new BehaviorSubject<boolean>(false);
  downloading$ = this.isDownloadRequest.asObservable();
  constructor() { }

  public downloadRequest(isRequest: boolean) {
    this.isDownloadRequest.next(isRequest);
  }
}
