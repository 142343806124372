import { Directive, Input, ElementRef, OnChanges, HostListener, SimpleChanges } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[inputValidator]'
})

export class InputValidatorDirective implements OnChanges {
    @Input() inputValidator: ElementRef;

    constructor(private el: ElementRef) { }

    @HostListener('input')
    ngOnChanges() {
        // const pattern = /^[0-9]*$/;
        const pattern = /^\d+(\.\d{1,2})?$/;
        if (!pattern.test(this.el.nativeElement.value)) {
            this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
        }
    }
}
