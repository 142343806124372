import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import { ICampaign, IFilter } from '../models/campaign.interface';
import { CampaignClass, ISelectOption } from '../models/campaign.model';
import { dependentRequired, requiredIfValidator } from '../validators/requiredIf.validator';
import { CampaignService } from './campaign.service';

@Injectable()
export class CampaignFormService {
  editMode: boolean;
  form: FormGroup;
  campaign: CampaignClass;
  oldCampaign: CampaignClass;
  constructor(
    private fb: FormBuilder,
    private campaignService: CampaignService
  ) {
    console.log('init CampaignFormService constructor');
    // this.initForm();
  }

  get filters(): FormArray { return this.audienceSelection.get('filters') as FormArray; }

  get basicDetails(): FormGroup { return this.form.get('basic_details') as FormGroup; }

  get audienceSelection(): FormGroup { return this.form.get('audience_selection') as FormGroup; }

  get rewardDetails(): FormGroup { return this.form.get('reward_details') as FormGroup; }

  get couponDesign(): FormGroup { return this.form.get('coupon_design') as FormGroup; }

  get notifications(): FormGroup { return this.form.get('notifications') as FormGroup; }

  get applicability(): FormGroup { return this.form.get('audience_selection.applicability') as FormGroup; }

  get couponDetails(): FormGroup { return this.form.get('reward_details.coupon_details') as FormGroup; }

  createFilter(withExtraParams?: boolean): FormGroup {
    const filters = {
      data: ['', Validators.required],
      kpi: this.fb.group({
        filter: ['', Validators.required],
        operator: ['', Validators.required],
        value: this.fb.group({})
      }),
      additional_filters: [''],
      additional_values: this.fb.array([]),
      order: [{ value: this.filters.length + 1, disabled: true }],
    };
    if (withExtraParams) {
      filters['condition'] = ['AND', Validators.required];
    }
    return this.fb.group(filters);
  }

  addAdditinalFilter(filter: ISelectOption) {
    const group = {
      filter: [filter],
      value: this.fb.group({})
    };
    return this.fb.group(group);
  }

  addFilter(withExtraParams?: boolean): FormGroup {
    const filterGroup = this.createFilter(withExtraParams);
    this.filters.push(filterGroup);
    return filterGroup;
  }

  removeFilter(index: number) { this.filters.removeAt(index); }

  createForm(): FormGroup {
    console.log('init createForm');
    return this.fb.group({
      basic_details: this.getBasicDetails(),
      reward_details: this.getRewardDetails(),
      audience_selection: this.getAudienceSelection(),
      coupon_design: this.getCouponDesign(),
      notifications: this.getNotifications()
    },
      // { validators: MyAwesomeRangeValidator }
    );
  }

  initForm(campaign?: CampaignClass) {
    if (!campaign) {
      console.log('new form');
      this.campaign = new CampaignClass();
    } else {
      this.editMode = true;
      console.log('edit mode', campaign);
      this.campaign = campaign;
    }
    this.oldCampaign = Object.assign({}, this.campaign);
    this.form = this.createForm();
    // load default filter in filter list.
    this.addFilter();
  }

  addDynamicFilters(filters: IFilter[]) {
    try {
      let i = 0;
      // add filters in FormArray
      for (const _filter of filters) {
        const flag = i === 0 ? false : true;
        if (flag) {
          this.addFilter(flag);
        }
        i++;
      }
      // this.filters.patchValue(filters);
    } catch (error) {
      console.log(error);
    }
  }

  addDynamicControl(dynamicControls: any[], data: ICampaign) {
    try {
      for (const _group of dynamicControls) {
        if (_group.type === 'formGroup') {
          if (data[_group.name]) {
            if (!data['parent']) {
              data[_group.name] = { ...data[_group.name], parent: _group.name };
            } else {
              data[_group.name] = {
                ...data[_group.name], parent: `${data['parent']}.${_group.name}`
              };
            }
            this.addDynamicControl(_group.controls, data[_group.name]);
          }
        } else {
          const fieldList = _group.addFormControls;
          for (const field of fieldList) {
            if (field.value === data[_group.name]) {
              const form = this.form.get(data['parent']) as FormGroup;
              const controlName = field.control.name;
              if (!form.contains(controlName)) {
                form.addControl(controlName, new FormControl(data[controlName], field.control.validation));
                this.updateValueAndValidity();
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  removeDynamicControl(controlName: string, ngControl: NgControl) {
    try {
      if (!ngControl.control) {
        return;
      }
      const parent = ngControl.control.parent as FormGroup;
      if (parent && parent.contains(controlName)) {
        setTimeout(() => {
          ngControl.control.clearValidators();
          ngControl.control.updateValueAndValidity();
          parent.removeControl(controlName);
          this.updateValueAndValidity();
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  updateValueAndValidity() {
    this.form.updateValueAndValidity();
  }

  filterDynamicControls(formGroupName: string) {
    return this.dynamicControls().filter(_group => _group.type === 'formGroup' && _group.name === formGroupName);
  }

  dynamicControls() {
    return [
      {
        type: 'formGroup',
        name: 'reward_details',
        controls: [
          {
            type: 'formControl',
            name: 'benifits_validity',
            addFormControls: [
              {
                value: 'days',
                control: {
                  disabled: false,
                  label: 'Days',
                  name: 'benifits_days',
                  placeholder: 'Days',
                  type: 'text',
                  validation: [Validators.required],
                }
              },
              {
                value: 'date_range',
                control: {
                  disabled: false,
                  label: 'Start Date',
                  name: 'benifits_start_date',
                  placeholder: 'Start Date',
                  type: 'text',
                  validation: [Validators.required],
                }
              },
              {
                value: 'date_range',
                control: {
                  disabled: false,
                  label: 'End Date',
                  name: 'benifits_end_date',
                  placeholder: 'End Date',
                  type: 'text',
                  validation: [Validators.required],
                }
              }
            ]
          },
          {
            type: 'formGroup',
            name: 'coupon_details',
            controls: [
              {
                type: 'formControl',
                name: 'coupon_usage',
                addFormControls: [
                  {
                    value: '2',
                    control: {
                      disabled: false,
                      label: 'Coupon Usage Numbers of Times',
                      name: 'coupon_usage_numbers_of_times',
                      placeholder: 'Coupon Usage Numbers of Times',
                      type: 'text',
                      validation: [Validators.required]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'formGroup',
        name: 'audience_selection',
        controls: [
          {
            type: 'formGroup',
            name: 'applicability',
            controls: [
              {
                type: 'formControl',
                name: 'applicable_at',
                addFormControls: [
                  {
                    value: 'selected_store',
                    control: {
                      disabled: false,
                      label: 'Stores',
                      name: 'stores',
                      placeholder: 'Stores',
                      type: 'text',
                      validation: [Validators.required]
                    }
                  }
                ],
              }
            ]
          }
        ]
      },
      {
        type: 'formGroup',
        name: 'coupon_design',
        controls: [
          {
            formControl: 'card_type',
            addFormControls: [
              {
                value: '2',
                control: {
                  disabled: false,
                  label: 'Title',
                  name: 'title',
                  placeholder: 'Title',
                  type: 'text',
                  validation: [Validators.required]
                }
              },
              {
                value: '2',
                control: {
                  disabled: false,
                  label: 'Subtitle',
                  name: 'subtitle',
                  placeholder: 'Subtitle',
                  type: 'text',
                  validation: [Validators.required]
                }
              },
              {
                value: '2',
                control: {
                  disabled: false,
                  label: 'Description',
                  name: 'description',
                  placeholder: 'Description',
                  type: 'text',
                  validation: [Validators.required]
                }
              }
            ],
          }
        ]
      },
    ];
  }

  resetStep(formGroupName: string) {
    try {
      // console.log('this.oldCampaign', this.oldCampaign);
      const stepData = this.oldCampaign[formGroupName];
      const stepCampainData = { [formGroupName]: stepData };
      const localCampaign = JSON.parse(localStorage.getItem('campaign'));
      const campaign = { ...localCampaign, [formGroupName]: stepData, };
      // console.log('campaign', campaign, stepCampainData);

      // add dynamic controls in FormGroup
      const dynamicControls = this.filterDynamicControls(formGroupName);

      // this.initForm(campaign);
      /*     if (formGroupName === 'audience_selection' && 'filters' in stepData && stepData.filters.length) {
            this.addDynamicFilters(stepData.filters);
          } */

      // console.log('before if dynamicControls', dynamicControls);
      if (dynamicControls.length) {
        this.addDynamicControl(dynamicControls, stepCampainData);
      }
      // console.log('stepData', stepData);
      // console.log('dynamicControls', dynamicControls);
      setTimeout(() => {
        this.form.patchValue(campaign);
      });
    } catch (error) {
      console.log(error);
    }
  }

  private getBasicDetails() {
    return this.fb.group({
      campaign_type: [this.campaign.basic_details.campaign_type, [Validators.required]],
      campaign_objective: [this.campaign.basic_details.campaign_objective, [Validators.required]],
      campaign_name: [
        this.campaign.basic_details.campaign_name,
        [
          Validators.required, Validators.minLength(5), Validators.maxLength(25)
        ]
      ],
      campaign_start_date: [this.campaign.basic_details.campaign_start_date, [Validators.required]],
      is_until_canceled: [this.campaign.basic_details.is_until_canceled], // Validators.requiredTrue
      campaign_end_date: [
        { disabled: this.campaign.basic_details.is_until_canceled, value: this.campaign.basic_details.campaign_end_date },
        [
          requiredIfValidator(() => !this.basicDetails.get('is_until_canceled').value)
          // dependentRequired('is_until_canceled', true)
        ]],
      terms_and_condition: [this.campaign.basic_details.terms_and_condition, [Validators.required]]
    });
  }

  private getRewardDetails() {
    return this.fb.group({
      type_of_reward: [this.campaign.reward_details.type_of_reward],
      benifits_value: [this.campaign.reward_details.benifits_value],
      benifits_validity: [this.campaign.reward_details.benifits_validity],
      coupon_required: [this.campaign.reward_details.coupon_required],
      coupon_details: this.fb.group({}), // this.getCouponDetails()
    });
  }

  private getAudienceSelection() {
    return this.fb.group({
      dataset: [this.campaign.audience_selection.dataset],
      filters: this.fb.array([]),
      applicability: this.fb.group({
        applicable_for: [this.campaign.audience_selection.applicability.applicable_for],
        applicable_at: [this.campaign.audience_selection.applicability.applicable_at],
        // stores: [this.campaign]
      }),
      /*   test_control: this.fb.group({
          test: [this.campaign],
          control: [this.campaign],
          total_audience_count: [this.campaign],
          coupon_quantity: [this.campaign]
        }) */
    });
  }

  private getCouponDesign() {
    return this.fb.group({
      card_type: [this.campaign.coupon_design.card_type],
      card_image_name: [this.campaign.coupon_design.card_image_name],
      card_image_path: [this.campaign.coupon_design.image],
      custom: this.fb.group({}) // this.getCustomCouponDesign()
    });
  }

  private getNotifications() {
    return this.fb.group({
      type_of_notifiction: [this.campaign.notifications.type_of_notifiction],
      expiry_reminders: this.fb.group({
        number_of_reminders: [this.campaign.notifications.expiry_reminders.number_of_reminders],
        reminders: this.fb.array([]) // this.campaign.notifications.expiry_reminders.reminders
      })
    });
  }

  getCouponDetails() {
    return {
      type_of_coupons: [this.campaign.reward_details.coupon_details.type_of_coupons, Validators.required],
      coupon_format: [this.campaign.reward_details.coupon_details.coupon_format, Validators.required],
      coupon_length: [this.campaign.reward_details.coupon_details.coupon_length, Validators.required],
      coupon_preffix: [this.campaign.reward_details.coupon_details.coupon_preffix, []],
      coupon_suffix: [this.campaign.reward_details.coupon_details.coupon_suffix, []],
      coupon_usage: [this.campaign.reward_details.coupon_details.coupon_usage, Validators.required],
      coupon_quantity_reminders: [
        this.campaign.reward_details.coupon_details.coupon_quantity_reminders,
        Validators.required
      ]
    };
  }

  getCustomCouponDesign() {
    return {
      title: [
        this.campaign.coupon_design.title,
        [
          Validators.minLength(3),
          Validators.maxLength(25),
          Validators.required
        ]
      ],
      sub_title: [
        this.campaign.coupon_design.sub_title,
        [
          Validators.minLength(3),
          Validators.maxLength(25),
          Validators.required
        ]
      ],
      description: [
        this.campaign.coupon_design.description,
        [
          Validators.minLength(3),
          Validators.maxLength(255),
          Validators.required
        ]
      ],
      background_color: [
        this.campaign.coupon_design.background_color,
        Validators.required],
      font_color: [
        this.campaign.coupon_design.font_color, Validators.required],
      logo_name: [
        this.campaign.coupon_design.logo_name, []
      ],
      logo_path: [
        this.campaign.coupon_design.logo_path, []
      ],
    };
  }

  storeSearchForm() {
    return this.fb.group({
      /*  state: [null, [Validators.required, Validators.minLength(3)]],
       city: [null, [Validators.required, Validators.minLength(3)]],
       stores: [null, Validators.required] */
      state: [null],
      city: [null],
      stores: [[]]
    });
  }
}
