
import { NgModule } from '@angular/core';
import { LayoutModule } from '../shared/layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    SharedModule,
    LayoutModule,
    RouterModule.forChild([])
  ]
})
export class AuthModule { }
