import { Directive, Input, OnInit, ElementRef, Renderer2, AfterViewInit, AfterContentInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[setRequiredFields]'
})
export class SetRequiredFieldsDirective implements AfterViewInit {
  @Input() formGroup: FormGroup;
  constructor(private element: ElementRef, private renderer: Renderer2) { }
  ngAfterViewInit() {
    // console.log('inputValidator', this.element);
    const formControls = this.formGroup.controls;
    const elementFields = this.element.nativeElement;
    //console.log(elementFields.elements);

    for (let field of elementFields) {
      // console.log(field);

      // console.log(field.getAttribute('formControlName'));
      // this.formGroup.get(fieldName).
      /*  let fieldName = null;
       if (field.getAttribute('type') === 'text' && (fieldName = field.getAttribute('formControlName')) !== null) {
         console.log(field);
         console.log('field', fieldName);
         try {
         console.log(  this.formGroup.get(fieldName));
           const validator = this.formGroup.get(fieldName).validator({} as AbstractControl);
           //  console.log(typeof validator);
         } catch (err) {
           console.log(`error ${field.getAttribute('formControlName')}`, err);
 
         }
 
       } */
      // console.log('field', field.getAttribute('formControlName'));
      let fieldName = null;
      if (field.getAttribute('type') === 'text' && (fieldName = field.getAttribute('formControlName')) !== null) {
        // console.log('fieldName', fieldName, field.nextSibling);

        const validator = this.formGroup.get(fieldName).validator({} as AbstractControl);

        //    this.renderer.nextSibling('label');
        // this.renderer.setAttribute(field, 'ng-reflect-required', 'true');
        // this.renderer.setAttribute(field, 'aria-required', 'true');

        if (validator && validator.required) {
          this.renderer.setProperty(field, 'required', 'true');
          //  console.log(field.getAttribute('formControlName'), true);
          // this.renderer.setAttribute()
          // return true;
        }
      }
    }
    /*  for (const field in formControls) {
       // console.log(field);
       //  console.log('elementFields', elementFields);
 
     } */

    /* Object.values(elementFields).forEach((controlName: HTMLElement, index) => {
      // const controlValue = formControls[controlName];
      // console.log(controlName.getAttribute('formControlName'), controlName, index);
      console.log(formControls);

    }); */
    //console.log(this.el, 'ele', this.el.nativeElement.getAttribute('formControlName'), this.el.nativeElement.form);

  }
}
