import { Directive, ElementRef, HostBinding, OnChanges, HostListener, Renderer2, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[IsInvalid]'
})
export class IsInvalidDirective implements OnChanges {
  // @HostBinding('class') class: any;
  parent: HTMLElement;
  siblingInput: any;
  hasClass: boolean;
  fromField: any;
  toField: any;
  constructor(private renderer: Renderer2, private el: ElementRef) {
    // this.class = this.el.nativeElement.classList;
  }

  @HostListener('input')
  ngOnChanges() {
    let fromValue: number;
    let toValue: number;
    const element = this.el.nativeElement;
    if (!element) {
      return;
    }
    const parent = this.renderer.parentNode(element);

    if (parent.nextSibling.firstChild) {
      this.toField = parent.nextSibling.firstChild;
      this.siblingInput = parent.nextSibling.firstChild;
    }

    if (parent.previousSibling.firstChild) {
      this.fromField = parent.previousSibling.firstChild;
      this.siblingInput = parent.previousSibling.firstChild;
    }

    if (this.fromField) {
      fromValue = parseFloat(this.fromField.value);
    }

    if (this.toField) {
      toValue = parseFloat(this.toField.value);
    }

    const elValue = parseFloat(element.value);
    if (fromValue) {

      if (elValue < fromValue) {
        this.renderer.addClass(this.fromField, 'is-invalid');
      } else {
        this.renderer.removeClass(this.fromField, 'is-invalid');
      }
    }


    if (toValue) {
      if (elValue > toValue) {
        this.renderer.addClass(element, 'is-invalid');
      } else {
        this.renderer.removeClass(element, 'is-invalid');
      }
    }

  }
}
