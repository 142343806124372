import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { ApiResult } from '../../../core/models/api-result';
import { TypesUtilsService } from '../../../core/services/types-utils.service';
import { SpinnerButtonOptions } from '../spinner-button/button-options.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InputData {
  type: number;
  mobile_number: string;
  current_otp?: number;
  otp_attempt?: number;
  existing_mobile_number?: string;
  new_mobile_number?: string;
}

@Component({
  selector: 'app-verify-opt',
  templateUrl: './verify-opt.component.html',
  styleUrls: ['./verify-opt.component.scss'],
  providers: [TypesUtilsService]
})
export class VerifyOptComponent implements OnInit, OnDestroy {
  verifyOtpForm: FormGroup;
  otpAttempts: boolean;
  spinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: '',
    spinnerColor: 'primary',
    fullWidth: false,
    mode: 'indeterminate'
  };

  resendSpinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: '',
    spinnerColor: 'primary',
    fullWidth: false,
    mode: 'indeterminate'
  };

  subscriptions: Subscription[] = [];
  otp;
  response = {
    next: (result: ApiResult) => this.handleResult(result),
    error: err => this.handleError(err),
    complete: () => this.complete(),
  };

  isOTPSent = false;
  isResendDisabled: boolean;
  defaultCounter = 30;
  showCounter: boolean;
  counter: number;
  timer: any;
  isReSendOTP: boolean;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private typesUtilsService: TypesUtilsService,
    public dialogRef: MatDialogRef<VerifyOptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData
  ) { }

  ngOnInit() {
    this.showCounter = false;
    this.verifyOtpForm = this.createForm();
  }

  closeDialog() {
    this.dialogRef.close();
  }
  createForm() {
    return this._formBuilder.group({
      otp: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      mobile_number: [this.data.mobile_number],
      old_mobile_number: [this.data['existing_mobile_number'] || null]
    });
  }

  inputValidator(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }

  requestOtp(): void {
    this.spinner.active = true;
    this.otpAttempts = false;

    const params = this.verifyOtpForm.getRawValue();
    this.subscriptions.push(this.userService.requestOtp(params).subscribe(this.response));
  }

  reSendOtp(): void {
    this.resendSpinner.active = true;
    this.isResendDisabled = true;
    this.isReSendOTP = true;
    const params = this.verifyOtpForm.getRawValue();
    this.otpAttempts = false;
    params['type'] = this.data.type;
    // console.log(this.loginForm.get('otp').errors)
    this.subscriptions.push(this.userService.reSendOtp(params).subscribe(this.response));
  }

  handleResult(result: ApiResult) {
    if (this.checkOtpResponse(result)) {
      return;
    }

    if (this.isResendDisabled) {
      // this.typesUtilsService.snackbarAlert('OTP send successfully.');
      this.isOTPSent = true;
      setTimeout(() => {
        this.isOTPSent = false;
      }, 4000);
    }
    this.resendSpinner.active = false;

    if (this.isReSendOTP) {
      this.counter = this.defaultCounter;
      this.showCounter = true;
      this.timer = setInterval(() => {
        --this.counter;
        if (this.counter === 0) {
          this.counter = this.defaultCounter;
          this.showCounter = false;
          clearInterval(this.timer);
        }
      }, 1000);
      setTimeout(() => {
        this.isReSendOTP = false;
        this.isResendDisabled = false;
      }, 30000);
    }
  }

  handleError(err) {
    this.spinner.active = false;
    this.resendSpinner.active = false;
    this.isResendDisabled = false;
  }

  complete() {
    // console.log('complete');
  }

  checkOtpResponse(result: ApiResult) {
    return this.isServerError(result) || this.attemptExeed(result);
  }

  isServerError(result: ApiResult) {
    if (result.data['statusCode'] === 105) {
      this.spinner.active = false;
      this.setFormError(result);
      return true;
    }
    return false;
  }

  attemptExeed(result: ApiResult) {
    if (result.data['otp_attempt'] >= 3) {
      this.spinner.active = false;
      this.otp = '';
      if (result.data['statusCode'] === 104) {
        this.otp = '';
        this.otpAttempts = true;
      }
      this.setFormError(result);

      return true;
    }
    // this.initCountDown(timeLeft);
    return false;
  }

  setFormError(result: ApiResult) {
    this.verifyOtpForm.setErrors({ 'server-error': true, message: result.message });
  }

  verifyOtp(): void {
    this.spinner.active = true;
    const params = this.verifyOtpForm.getRawValue();
    params['type'] = this.data.type;

    this.subscriptions.push(this.userService.verifyOtp(params).subscribe(result => {

      if (this.isServerError(result)) {
        return;
      }

      const otpControl = this.verifyOtpForm.get('otp');

      if (this.attemptExeed(result)) {
        otpControl.reset();
        return;
      }

      this.spinner.active = false;

      if (result.data['statusCode'] === 104) {
        otpControl.enable();
        otpControl.markAsTouched();
        otpControl.updateValueAndValidity();
        this.verifyOtpForm.get('otp').setErrors({ 'invalidOtp': true, message: result.message });
        return;
      }

      if (this.validRegisterStatus(params, result)) {
        return;
      }
    }, error => {
      this.verifyOtpForm.get('otp').reset();
    }));
  }

  validRegisterStatus(params: any, result: ApiResult) {
    this.otp = '';
    this.verifyOtpForm.disable();
    // console.log(this.data, result);
    this.dialogRef.close(this.data);
    if (result.data['isRegister'] === 1) {
      return false;
    }

    if (result.data['isRegister'] === 0 || result.data['isRegister'] === 201) {
      return false;
    }

    return false;
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
