import { Directive, Input, ElementRef, OnChanges, HostListener, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[DecimalNumber]'
})

export class DecimalNumberDirective implements OnChanges {
    @Input() inputValidator: ElementRef;

    constructor(private el: ElementRef, private control: NgControl) { }

    @HostListener('input')
    ngOnChanges() {
        // const pattern = /^[0-9]*$/;
        const pattern = /^\d+(\.\d{1,2})?$/;
        if (!pattern.test(this.el.nativeElement.value)) {
            const inputValue = this.el.nativeElement.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
            this.control.control.setValue(inputValue);
            this.el.nativeElement.value = inputValue;
        }
    }
}
