import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as SecureLS from 'secure-ls';
import { User } from 'src/app/customer/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  ENCRYPTION_KEY = environment.encriptionKey;
  tempRegisterData = null;
  isAllowRegister: boolean;
  registerDataKey = 'cRa';

  constructor(private router: Router) { }

  public setRegisterData(token: string): any {
    this.tempRegisterData = token;
    localStorage.setItem(this.registerDataKey, token); // cRa - customer register data
  }

  public getRegisterData(): string {
    if (this.tempRegisterData) {
      this.setRegisterData(this.tempRegisterData);
      return this.tempRegisterData;
    }
    const registerData: string = <string>localStorage.getItem(this.registerDataKey); // cRa - customer register data
    this.tempRegisterData = registerData;
    return registerData;
  }

  public setUserRegisterInfo(userInfo: User): any {
    if (userInfo != null) {
      const secureLS = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: this.ENCRYPTION_KEY });
      secureLS.set(this.registerDataKey, JSON.stringify(userInfo));
    }
    return this;
  }

  public getUserInfo(): User {

    try {
      const secureLS = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: this.ENCRYPTION_KEY });
      const userInfo = secureLS.get(this.registerDataKey);
      if (userInfo) {
        return JSON.parse(userInfo);
      }
    } catch (err) {
      // console.log('error in parsing', err);
      this.clear();
      this.router.navigate(['/login']);
    }
  }

  /**
  * Remove tokens
  */
  public clear() {
    localStorage.removeItem(this.registerDataKey);
    localStorage.clear();
    this.tempRegisterData = null;
  }
}
