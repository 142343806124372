import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResult } from 'src/app/core/models/api-result';
import { User } from 'src/app/customer/models/user.model';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { TokenStorage } from 'src/app/core/services/authentication/token-storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { SpinnerButtonOptions } from 'src/app/shared/components/spinner-button/button-options.interface';
import { TypesUtilsService } from 'src/app/core/services/types-utils.service';
import { environment } from 'src/environments/environment';
import { SlideUpDownAnimation } from '../../../core/animation/collapse-animate';
import { RegisterService } from '../register/services/register.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [SlideUpDownAnimation],
  providers: [UserService]
})
export class LoginComponent implements OnInit, OnDestroy {
  hide: boolean;
  isRequestForOtp: boolean;
  animationState = 'down';
  loginForm: FormGroup;
  user: User = new User();
  otp: string;
  otpAttempts: boolean;
  clock: string;
  spinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: '',
    spinnerColor: 'primary',
    fullWidth: false,
    mode: 'indeterminate'
  };
  subscriptions: Subscription[] = [];

  response = {
    next: (x: ApiResult) => this.handleResult(x),
    error: err => this.handleError(err),
    complete: () => this.complete(),
  };
  resendTab: boolean;
  reSendOTP: boolean;
  resendDisabled: boolean;
  defaultCounter = 30;
  showCounter: boolean;
  counter: number;
  timer: any;
  storeCode;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private registerService: RegisterService,
    private tokenStorage: TokenStorage,
    private authService: AuthService,
    private typesUtilsService: TypesUtilsService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.showCounter = false;
    this.hide = true;
    this.loginForm = this.createForm();
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.storeCode = params['store'];
    }))
  }

  // [Validators.minLength(6),Validators.maxLength(6)]
  createForm() {
    return this._formBuilder.group({
      mobile_number: [this.user.mobile_number, [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      otp: [{ disabled: true, value: this.user.otp }, Validators.minLength(6)]
    });
  }

  inputValidator(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }


  toggleShowDiv(divName: string) {
    if (divName === 'field-otp') {
      this.animationState = this.animationState === 'down' ? 'up' : 'down';
    }
  }

  attemptExeed(result: ApiResult) {
    if (result.data['otp_attempt'] >= 3) {
      this.spinner.active = false;
      this.updateValidation();
      if (result.data['statusCode'] === 104) {
        this.otp = '';
        this.otpAttempts = true;
      }
      this.setFormError(result);

      return true;
    }
    // this.initCountDown(timeLeft);
    return false;
  }

  isServerError(result: ApiResult) {
    if (result.data['statusCode'] === 105) {
      this.spinner.active = false;
      this.setFormError(result);
      return true;
    }
    return false;
  }

  setFormError(result: ApiResult) {
    this.loginForm.setErrors({ 'server-error': true, message: result.message });
  }

  showOtp(result: ApiResult) {
    if (result.data) {
      if (!this.reSendOTP) {
        this.toggleShowDiv('field-otp');
      }
      if (this.reSendOTP) {
        this.counter = this.defaultCounter;
        this.showCounter = true;
        this.timer = setInterval(() => {
          --this.counter;
          if (this.counter === 0) {
            this.counter = this.defaultCounter;
            this.showCounter = false;
            clearInterval(this.timer);
          }
        }, 1000);
        setTimeout(() => {
          this.resendDisabled = false;
        }, 30000);
      }
      this.isRequestForOtp = true;
      this.updateValidation();
      this.spinner.active = false;
      this.typesUtilsService.snackbarAlert(result.message);
    }
  }

  checkOtpResponse(result: ApiResult) {
    return this.isServerError(result) || this.attemptExeed(result);
  }

  requestOtp(): void {
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    localStorage.setItem(environment.returnUrl, returnUrl);
    this.spinner.active = true;
    this.otpAttempts = false;
    this.resendTab = true;
    const params = this.loginForm.getRawValue();
    params.storeCampaign = this.storeCode;
    this.subscriptions.push(this.userService.requestOtp(params).subscribe(this.response));
  }

  reSendOtp(): void {
    this.resendDisabled = true;
    this.reSendOTP = true;
    const params = this.loginForm.getRawValue();
    this.otpAttempts = false;
    // this.toggleShowDiv('field-otp');
    // console.log(this.loginForm.get('otp').errors)
    this.subscriptions.push(this.userService.reSendOtp(params).subscribe(this.response));
  }

  validRegisterStatus(params: any, result: ApiResult) {
    this.otp = '';
    this.loginForm.disable();

    if (result.data['isRegister'] === 1 || result.data['storeCode'] && result.data['storeCode'] === this.storeCode) {
      this.tokenStorage.setAccessToken(result.data.userData).setUserActions(result.data.userData).setDefaultPath(result.data.userData);
      this.authService.setCurrentUser();
      let returnUrl = '/dashboard/index';
      if ((returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')) !== null) {
        localStorage.setItem(environment.returnUrl, returnUrl);
        localStorage.setItem('prevUrl', '/login');
      } else {
        returnUrl = this.tokenStorage.getDefaultPath();
      }
      localStorage.setItem('firstVisit', result.data['firstVisit']);
      localStorage.setItem('couponCode', result.data['couponCode']);
      localStorage.setItem('storeName', result.data['storeName']);
      localStorage.setItem('storeLocationName', result.data['storeLocationName']);
      this.router.navigateByUrl(returnUrl);
      return false;
    }

    if (result.data['isRegister'] === 0 || result.data['isRegister'] === 201) {
      // delete result.data.isRegister;
      delete result.data.statusCode;
      this.registerService.isAllowRegister = true;
      const userParams = Object.assign(params, result.data);
      userParams['isAllowRegister'] = true;
      userParams['loginTime'] = (new Date().getTime() + 300000); // add 5 minutes
      this.registerService.setUserRegisterInfo(userParams);
      this.router.navigate(['register']);
      return false;
    }

    return true;
  }
  verifyOtp(): void {
    this.spinner.active = true;
    const params = this.loginForm.getRawValue();
    params.storeCampaign = this.storeCode;
    this.subscriptions.push(this.userService.verifyOtp(params).subscribe(result => {
      if (result.message === 'Invalid OTP.') {
        this.loginForm.get('otp').setValue(null);
      }
      if (this.isServerError(result)) {
        return;
      }

      const otpControl = this.loginForm.get('otp');

      if (this.attemptExeed(result)) {
        otpControl.reset();
        this.toggleShowDiv('field-otp');
        return;
      }

      this.spinner.active = false;

      if (result.data['statusCode'] === 104) {
        otpControl.enable();
        otpControl.markAsTouched();
        otpControl.updateValueAndValidity();
        this.loginForm.get('otp').setErrors({ 'invalidOtp': true });
        return;
      }

      if (this.validRegisterStatus(params, result)) {
        return;
      }
    }, error => {
      this.loginForm.get('otp').reset();
      // console.log(error);
    }));
  }

  updateValidation(): void {
    const otpControl = this.loginForm.get('otp');
    const mobileControl = this.loginForm.get('mobile_number');
    otpControl.clearValidators();
    if (this.isRequestForOtp) {
      mobileControl.disable();
      mobileControl.updateValueAndValidity();
      otpControl.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
      otpControl.enable();
    }

    if (this.otpAttempts) {
      this.loginForm.disable();
    }
    otpControl.reset();
    otpControl.updateValueAndValidity();
  }

  changeNumber() {

  }

  handleResult(result: ApiResult) {
    if (this.checkOtpResponse(result)) {
      return;
    }
    this.showOtp(result);
  }

  handleError(err) {
    // console.log(err);
    this.spinner.active = false;
  }

  complete() {
    // console.log('complete');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}