import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { TokenStorage } from 'src/app/core/services/authentication/token-storage.service';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  avatarImgSrc: string = 'assets/images/avatar.png';
  userName: string = 'Folisise Chosielie';
  userPost: string = 'Musician, Player';
  user: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenStorage: TokenStorage) { }

  ngOnInit() {
  }

  profieLogin() {
    if (this.authService.currentUser.isCustomer) {
      this.router.navigate(['/dashboard/profile']);
    } else if (this.authService.currentUser.isMerchant) {
      this.router.navigate(['/merchant/profile']);
    } else if (this.authService.currentUser.isMerchantAdmin) {
      this.router.navigate(['/admin/profile']);
    } else {
      this.authService.logout();
    }
  }

  logout() {
    this.authService.logout();
    const element = document.querySelector('body');
    element.classList.remove('customer-class');
  }
}
