import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { TokenStorage } from 'src/app/core/services/authentication/token-storage.service';
import { CommonService } from '../services/common.service';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private tokenStorage: TokenStorage, private commonService: CommonService) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available

        if (request.body && 'filter' in request.body && request.body.filter['exportType']) {
            this.commonService.downloadRequest(true);
        }

        const setHeaderParams = {
            'Content-Type': 'application/json',
        };

        const token = this.tokenStorage.getAccessToken();

        if (token) {
            setHeaderParams['Authorization'] = `${token}`;
        }

        request = request.clone({
            setHeaders: setHeaderParams,
        });

        return next.handle(request);
    }
}
