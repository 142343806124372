import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiResult } from '../models/api-result';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';

const SERVER_URL = environment.serverUrl;

@Injectable({
  providedIn: 'root'
})

export class IpService {
  private handleError: HandleError;
  private errorRepsonse: ApiResult;

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = this.httpErrorHandler.createHandleError('IpService');
  }

  get(endpoint, params): Observable<ApiResult> {

    if (Object.keys(params).length) {
      return this.http.get<ApiResult>(`${SERVER_URL}/${endpoint}/${params}`);
    } else {
      return this.http.get<ApiResult>(`${SERVER_URL}/${endpoint}`);
    }
  }

  post(endpoint, params): Observable<ApiResult> {
    return this.http.post<ApiResult>(`${SERVER_URL}/${endpoint}`, params);
  }

  put(endpoint, params): Observable<ApiResult> {
    return this.http.put<ApiResult>(`${SERVER_URL}/${endpoint}/`, params);
  }

  delete(endpoint, params): Observable<ApiResult> {
    return this.http.delete<ApiResult>(`${SERVER_URL}/${endpoint}/${params}`);
  }

  /* put(endpoint, params): Observable<ApiResult> {
    return this.http.put<ApiResult>(`${SERVER_URL}/${endpoint}/`, params).pipe(
      catchError(this.handleError(endpoint, params))
    );
  } */
}
