import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { TypesUtilsService } from '../../../core/services/types-utils.service';
import { DateConstant } from '../constant/date-constant';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent implements OnInit {
  authContent : any;
  dateFormate: any;
  constructor(private auth : AuthService,
    private typesUtilsService : TypesUtilsService
    ) { }

  ngOnInit() {
    this.dateFormate = DateConstant.dateFormate;
    this.authContent = this.auth.currentUser;
  }
  getDate(inputDate){
    if(!inputDate){
      return 'NA';
    }
    const index = inputDate.indexOf(' ');
    let finalDate = inputDate;
    if(index !== -1){
       finalDate = inputDate.substring(0,index + 1);
    }
      
    return this.typesUtilsService.transformDate(finalDate)
    
  }

}
