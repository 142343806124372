import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapitalTransformDirective } from './directives/capital-transform.directive';
import { DecimalNumberDirective } from './directives/DecimalNumberValidatorDirective';
import { ElementExistDirective } from './directives/element-exist.directive';
import { FilterRowDirective } from './directives/filter-row.directive';
import { AutofoucusDirective } from './directives/focus-directive';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';
import { InputValidatorDirective } from './directives/InputValidatorDirective';
import { IsInvalidDirective } from './directives/is-invalid.directive';
import { NumberValidatorDirective } from './directives/NumberValidatorDirective';
import { PortletDirective } from './directives/portlet.directive';
import { ScrollerDirective } from './directives/scroller.directive';
import { SetRequiredFieldsDirective } from './directives/set-required-fields.directive';
import { MaterialModule } from './material/material.module';
import { AbbrevateNumberPipe } from './pipes/abbrevate-number.pipe';
import { CapsFirstPipe } from './pipes/caps-first.pipe';
import { CapsLettersPipe } from './pipes/caps-letters.pipe';
import { CommonPipe } from './pipes/common.pipe';
import { IsBooleanPipe } from './pipes/is-boolean.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SnakeToTitleCasePipe } from './pipes/snake-to-title-case.pipe';
import { UnescapeDataPipe } from './pipes/unescape-data.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    // directives
    AutofoucusDirective,
    InputValidatorDirective,
    PortletDirective,
    SetRequiredFieldsDirective,
    ScrollerDirective,
    // pipes
    AbbrevateNumberPipe,
    CapsFirstPipe,
    CapsLettersPipe,
    SafeHtmlPipe,
    UnescapeDataPipe,
    NumberFormatPipe,
    CommonPipe,
    IsBooleanPipe,
    SnakeToTitleCasePipe,
    IsInvalidDirective,
    FilterRowDirective,
    NumberValidatorDirective,
    DecimalNumberDirective,
    CapitalTransformDirective,
    FocusInvalidInputDirective,
    ElementExistDirective
  ],
  exports: [
    // directives
    AutofoucusDirective,
    InputValidatorDirective,
    PortletDirective,
    SetRequiredFieldsDirective,
    ScrollerDirective,
    IsInvalidDirective,
    FilterRowDirective,
    NumberValidatorDirective,
    DecimalNumberDirective,
    CapitalTransformDirective,
    FocusInvalidInputDirective,
    ElementExistDirective,
    // pipes
    AbbrevateNumberPipe,
    CapsFirstPipe,
    CapsLettersPipe,
    SafeHtmlPipe,
    UnescapeDataPipe,
    NumberFormatPipe,
    CommonPipe,
    IsBooleanPipe,
    SnakeToTitleCasePipe,
    // modules
    MaterialModule,
  ],
})
export class CoreModule { }
