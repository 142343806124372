import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IpService } from 'src/app/core/services/ip.service';
import { StateResponse, CityResponse } from './location-model';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private ipService: IpService) { }

  getState(params): Observable<any> {
    return this.ipService.post('api/merchant-customers/getState', params).pipe(map((res) => res.data));

  }
  getCity(params): Observable<any> {
    return this.ipService.post('api/merchant-customers/getCity', params).pipe(map(res => res.data));
  }
  getMerchantLocations(params): Observable<any> {
    return this.ipService.post('api/merchant-customers/getStoreLocations', params).pipe(
      map(res => {
        if (Object.keys(res.data).length && 'location_list' in res.data) {
          return res.data['location_list'];
        }
        return [];
      })
    );;

  }
  insertHomeBranchChangeLog(params): Observable<any> {
    return this.ipService.post('api/merchant-customers/insertHomeBranchChangeLog', params);
  }
}
