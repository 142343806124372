import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'auth-landing',
  templateUrl: './auth-landing.component.html',
  styleUrls: ['./auth-landing.component.scss']
})
export class AuthLandingComponent implements OnInit {

  defaultRoute = '/';
  constructor() { }

  @Input('updatedRoute') updatedRoute: string | null;
  ngOnInit() {
    if(this.updatedRoute){
      this.defaultRoute = this.updatedRoute;
    }    
  }

}
