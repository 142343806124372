import { AbstractControl, Validators, ValidationErrors, FormGroup } from '@angular/forms';
import { isObject, isNull } from 'util';

export function requiredIfValidator(predicate) {
    return ((formControl: AbstractControl) => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate()) {
            return Validators.required(formControl);
        }
        return null;
    });
}

export function dependentRequired(dependentFieldName: string, dependentFieldValue: any) {
    return ((formControl: AbstractControl): ValidationErrors | null => {
        if (!formControl.parent) {
            return null;
        }

        const parentForm = formControl.parent as FormGroup;
        if (!parentForm.contains(dependentFieldName)) {
            return null;
        }

        if (parentForm.get(dependentFieldName).value === dependentFieldValue) {
            return Validators.required(formControl);
        }

        return null;
    });
}

export function objectRequired(label: string) {
    return ((formControl: AbstractControl): ValidationErrors | null => {
        if (!formControl.parent) {
            return null;
        }
        const value = formControl.value;
        if (value && !isObject(value)) {
            return { serverError: true, message: `${label} need match available options` }
        }
    });
}


export function valueIsNull(label: string) {
    return ((formControl: AbstractControl): ValidationErrors | null => {
        if (!formControl.parent) {
            return null;
        }
        const value = formControl.value;
        // Required
        if (isNull(value)) {
            return { serverError: true, message: `${label} field cannot be left blank.` }
        }
    });
}
