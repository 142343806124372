import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capsLetters'
})
export class CapsLettersPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return value.toLowerCase().replace(/\b\w{3,}/g, function (l) {
      return l.charAt(0).toUpperCase() + l.slice(1);
    });
  }

}
