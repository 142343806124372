import { Component, OnInit, AfterContentInit, Input, Inject, Renderer2 } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { collapse } from '../../../core/animation/collapse-animate';
import { SlideUpDownAnimation } from '../../../core/animation/collapse-animate';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'du-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [collapse, SlideUpDownAnimation]
})
export class MenuComponent implements OnInit {
  @Input() menuInfo: any;
  deviceInfo = null;

  customerToggle = 'off';
  isMobile: boolean;
  customerDashboard: boolean = false;

  constructor(private _globalService: GlobalService, private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    location: PlatformLocation
  ) {
    location.onPopState(() => {
      this.renderer.removeClass(this.document.body, 'no-footer');
    });
  }

  ngOnInit() {
    if (this.authService.currentUser && this.authService.currentUser.isCustomer) {
      this.renderer.addClass(this.document.body, 'customer-class');
      this.renderer.removeClass(this.document.body, 'merchant-class');
      this.customerDashboard = true;
    } else if (this.authService.currentUser && (this.authService.currentUser.isMerchant || this.authService.currentUser.isMerchantAdmin)) {
      this.renderer.addClass(this.document.body, 'merchant-class');
      this.renderer.removeClass(this.document.body, 'customer-class');
    }

    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
      });
    //  this.isMobile = this.deviceService.isMobile();
  }

  private isToggleOn(item) {
    item.toggle === 'on' ? item.toggle = 'off' : item.toggle = 'on';
  }

  private isToggleOnMore(customerToggle) {
    this.customerToggle === 'off' ? this.customerToggle = 'on' : this.customerToggle = 'off';
  }
  private _selectItem(item) {
    this.customerToggle = 'off';
    localStorage.removeItem('storeLocation');
    this._globalService.dataBusChanged('isActived', item);
  }

}
