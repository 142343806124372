import { Injectable } from '@angular/core';
import { IpService } from '../../../core/services/ip.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyEmailAddressService {

  constructor(
    private ipService: IpService
  ) { }

  verifyEmailAddress(params) {
    // console.log("params",params);
    return this.ipService.post('api/shared-controller/verifyEmailAddress', params);
  }

}
