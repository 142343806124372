import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';
import { Router, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationCancel } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'admin-pages-top',
  templateUrl: './admin-pages-top.component.html',
  styleUrls: ['./admin-pages-top.component.scss']
})
export class AdminPagesTopComponent implements OnInit, OnDestroy {

  defaultRout = 'index';
  sidebarToggle: boolean = true;

  constructor(
    private _globalService: GlobalService,
    private router: Router,
    public loader: LoadingBarService) {
    // page progress bar percentage
   /*  this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
      }
    }); */
  }

  ngOnInit() {
  }

  public _sidebarToggle() {
    this._globalService.data$.subscribe(data => {
      if (data.ev === 'sidebarToggle') {
        this.sidebarToggle = data.value;
      }
    }, error => {
      // console.log('Error: ' + error);
    });
    this._globalService.dataBusChanged('sidebarToggle', !this.sidebarToggle);
  }
  ngOnDestroy() {
    this.defaultRout = 'index';
  }
}
