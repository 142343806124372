import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'p-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {

  @Input() classes: any = '';
  @Input() icon: any;

  constructor() { }

  ngOnInit() {
    if (this.icon) {
      this.icon = `fa ${this.icon} m-alert--icon`;
    }
  }

}
