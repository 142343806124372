import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[capitalTransform]'
})
export class CapitalTransformDirective {

  constructor(private _el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value.toUpperCase();
    this.control.control.setValue(initalValue);
    this._el.nativeElement.value = initalValue;
  }

}
