import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capsFirst'
})
export class CapsFirstPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.toLowerCase().replace(/(?=\w{0,3}['-])([a-zA-Z '-]*)/g, function (l) {
      let x = l.slice(3);
      return l.slice(0,3).toUpperCase() + x.toLowerCase().replace(/\b\w{3,}/g, function (subStr) {
        return subStr.charAt(0).toUpperCase() + subStr.slice(1);
      });
    });
  }

}
