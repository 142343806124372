export class User {
    id?: number;
    user_id?: number;
    first_name?: string;
    user_type_id: number;
    last_name?: string;
    email_address?: string;
    mobile_number?: number;
    contact?: number;
    gender?: number;
    status?: number; // 0 = Active | 1 = Inactive
    avatar?: string;
    type?: number;
    otp?: number;
    date_of_birth?: any;
    marital_status?: number;
    anniversary_date?: any;
    spouse_dob?: any;
    username?: string;
    password?: string;
    isLoggedin: boolean;
    sub_merchant_id: number;
    sub_merchant_location_id: number;
    email: string;
    created_by: number;
    isAllowRegister: boolean;
    loginTime: number;
    isRegister: number; // 0 - new user, 201 existisng user but profile incomplete
    token?: string;
    isCustomer?: boolean;
    isMerchantAdmin?: boolean;
    isMerchant?: boolean;
    home_branch_id?: number;
    mobile_verified?: number;
    request_source?: number;
    customer_loyalty_id?: number;
    pin_code?: any;
    city_name?: any;
    city_id?: any;
    home_branch_name?: string;
    terms_conditions?: any;
    clear() {
        this.id = null;
        this.user_id = null;
        this.first_name = null;
        this.user_type_id = null;
        this.last_name = null;
        this.email_address = null;
        this.mobile_number = null;
        this.gender = null;
        this.status = null;
        this.avatar = null;
        this.type = null;
        this.otp = null;
        this.date_of_birth = null;
        this.marital_status = null;
        this.anniversary_date = null;
        this.spouse_dob = null;
        this.username = null;
        this.password = null;
        this.isLoggedin = false;
        this.sub_merchant_id = null;
        this.sub_merchant_location_id = null;
        this.email = null;
        this.created_by = null;
        this.isAllowRegister = false;
        this.loginTime = null;
        this.isRegister = null;
        this.token = null;
        this.home_branch_id = null;
        this.isCustomer = false;
        this.isMerchant = false;
        this.mobile_verified = null;
        this.request_source = null;
        this.customer_loyalty_id = null;
        this.pin_code = null;
        this.city_name = null;
        this.city_id = null;
        this.terms_conditions = null;
    }
}