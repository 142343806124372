import { Injectable } from '@angular/core';
import { IpService } from '../../../core/services/ip.service';

@Injectable({
  providedIn: 'root'
})
export class CancelMobileChangeRequestService {

  constructor(
    private ipService:IpService
  ) { }

  changeRequestStatus(params){
    return this.ipService.post('api/shared-controller/changeRequestStatus',params);
  }
}
