import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColumnDefination } from 'src/app/core/models/interfaces/column-defination.interface';

// import { MatDatepickerInputEvent } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export class DateChangeObject {
  event: MatDatepickerInputEvent<Date>;
  fieldName: string;
}

@Component({
  selector: 'app-table-filters-fields',
  templateUrl: './table-filters-fields.component.html',
  styleUrls: ['./table-filters-fields.component.scss']
})
export class TableFiltersFieldsComponent implements OnInit {
  @Input() tableDefination: ColumnDefination[];
  @Input() tableColumns: string[];
  @Input() filterColumns: string[];
  @Output() selectChangeEvent = new EventEmitter<{ value: any; fieldName: string }>();
  @Output() dateChangeEvent = new EventEmitter<DateChangeObject>();
  @Input() form: FormGroup;
  constructor() { }

  ngOnInit() {
    // console.log(this.filterColumns);
    // console.log(this.tableColumns);
    // console.log(this.tableDefination);


  }

  selectChangeEnvt(value: any, fieldName: string) {
    this.selectChangeEvent.emit({ value, fieldName });
  }

  dateChangeEvnt(event: MatDatepickerInputEvent<Date>, fieldName: string) {
    this.dateChangeEvent.emit({ event, fieldName });
  }
}
