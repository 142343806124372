import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { OptOutService } from './opt-out.service';

@Component({
  selector: 'app-otp-out',
  templateUrl: './otp-out.component.html',
  styleUrls: ['./otp-out.component.scss']
})
export class OtpOutComponent implements OnInit {
  data: any;
  idObject: any;
  subscriptions: Subscription[] = [];
  message: string;
  success: boolean;
  emailSmsType: any;
  idObject1: any;
  isSearchInProgress: boolean;
  isOverlayVisible: boolean = false;
  smsEmailTitle: string;
  disabledButton: boolean;
  constructor(private route: ActivatedRoute,
    private optOutService: OptOutService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, private authService: AuthService) { }

  ngOnInit() {
    try {
      this.renderer.addClass(this.document.body, 'customer-class');
      this.data = this.route.snapshot.queryParams['q'];
      var base64Url = this.data.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      this.idObject = JSON.parse(window.atob(base64));
      const type = this.idObject.type;
      if (type === 1) {
        this.smsEmailTitle = 'SMS';
      } else if (type === 2) {
        this.smsEmailTitle = 'EMAIL';
      }
    } catch (err) {
      console.error(err);
    }
  }

  unsubscribe() {
    this.isOverlayVisible = !this.isOverlayVisible;
    try {
      this.isSearchInProgress = true;
      const base64Url = this.data.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      this.idObject = JSON.parse(window.atob(base64));
      this.emailSmsType = this.idObject.type;
      const sendData = {
        'email_address': this.idObject.email_address,
        'mobile_number': this.idObject.mobile_number,
        'first_name': this.idObject.first_name,
        'last_name': this.idObject.last_name,
        'type': this.idObject.type
      };

      this.subscriptions.push(this.optOutService.optOut(sendData).subscribe(
        result => {
          this.success = true;
          if (result.data && result.statusCode == 200) {
            this.isSearchInProgress = false;
            this.isOverlayVisible = false;
            this.authService.swalFireFunction('Unsubscribe', 'You are unsubscribed', 'success').then(
              res => {
                if (res.value) {
                  this.disabledButton = true;
                }
              }
            );
          }
        }, err => {
          this.isSearchInProgress = false;
          this.isOverlayVisible = false;
          this.authService.swalFireFunction('Unsubscribe', 'You can\'t unsubscribe', 'error')
          this.success = false;
        }
      )
      );
    } catch (err) {
      this.message = `Email couldn't be verified`;
      console.log(err);
    }
  }
}
