import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { CommonService } from '../services/common.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private commonService: CommonService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body && 'data' in evt.body && evt.body.data['is_export'] === 1) {
                        this.commonService.downloadRequest(false);
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                const allowAlertStatus = [500, 400, 404, 0, 520];
                if (allowAlertStatus.indexOf(error.status) !== -1) {
                    const message = 'Something went wrong. Please try again later.';
                    this.authService.alertToUser(message, 'red-snackbar');
                    return new Observable<HttpEvent<any>>();
                }

                if (error.status === 401) {
                    this.authService.alertToUser('Your session expired.', 'red-snackbar');
                    this.authService.logout();
                    // return new Observable<HttpEvent<any>>();
                }
                return throwError(error);
            }),
            // finalize(() => console.log('finalize'))
        );
    }

    checkHeaderStatusKeys(event: HttpResponse<any>): boolean {
        return !event['type'] || event['type'] && 'status' in event && 'statusText' in event;
    }

    checkHeaderStatus(event: HttpResponse<any>): boolean {
        return event['status'] === 200 && event['statusText'] === 'OK';
    }

    checkApiResponse(event: HttpResponse<any>): boolean {
        return !event['type'] || 'body' in event && 'status' in event['body'] && event['body']['status'] === 'SUCCESS';
    }

    checkBodyResponse(event: HttpResponse<any>): boolean {
        return 'body' in event && 'status' in event.body;
    }

}
