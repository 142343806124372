import { Directive, AfterContentInit, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[autoFocus]'
})
export class AutofoucusDirective implements AfterContentInit {
    @Input() public appAutoFocus: boolean;

     constructor(private el: ElementRef) {

    }

     ngAfterContentInit() {
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 200);
    }
}
