import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { SlideUpDownAnimation } from 'src/app/core/animation/collapse-animate';
import { MatChipInputEvent } from '@angular/material/chips';
export interface Email {
  email: string;
  invalid: boolean;
}

@Component({
  selector: 'app-download-report-confirm-dialog',
  templateUrl: './download-report-confirm-dialog.component.html',
  styleUrls: ['./download-report-confirm-dialog.component.scss'],
  animations: [SlideUpDownAnimation],
})
export class DownloadReportConfirmDialogComponent implements OnInit, OnDestroy {
  requestForm: FormGroup;
  animationState = 'down';
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: Email[] = [];
  @ViewChild('emailChipList') emailChipList;
  constructor(
    public dialogRef: MatDialogRef<DownloadReportConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.emails.push({ email: this.data.defaultEmail, invalid: false });
    this.requestForm = this.createForm();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  createForm() {
    return this._formBuilder.group({
      // defaultEmail: [{ disabled: true, value: this.data.defaultEmail }],
      // changeEmail: [0],
      requestEmail: [null]
    });
  }

  cancel() {
    this.requestForm.reset();
    this.dialogRef.close({ isClosed: true });
  }

  updateValidation(changeStatus: number | null): void {
    const requestEmailControl = this.requestForm.get('requestEmail');

    if (changeStatus === 1) {
      requestEmailControl.setValidators([Validators.required]);
    } else {
      this.emails = [];
      requestEmailControl.clearValidators();
      requestEmailControl.reset();
    }
    requestEmailControl.updateValueAndValidity();
  }

  requestOnNewEmail(event: MatRadioChange) {
    this.toggleShowDiv(event.value);
    this.updateValidation(event.value);
  }

  toggleShowDiv(changeStatus: number | null) {
    let slideState: string;
    if (changeStatus === 0) {
      slideState = 'down';
    } else {
      slideState = 'up';
    }
    this.animationState = slideState;
  }

  validEmails(emailList) {
    this.emailChipList.errorState = false;
    let isError = false;
    const maxEmailAllowed = 3;
    const control = this.requestForm.get('requestEmail');

    if (!emailList.length) {
      control.setErrors({ 'required': true });
      this.emailChipList.errorState = true;
      // control.updateValueAndValidity();
      control.markAsTouched();
      // control.updateValueAndValidity();
      return isError = true;
    }

    // if (emailList.length > maxEmailAllowed) {
    //   this.emailChipList.errorState = true;
    //   control.setErrors({ 'maxEmail': true });
    //   control.markAsTouched();
    //   isError = true;
    // }

    // if (emailList.length <= maxEmailAllowed) {
    //   this.emailChipList.errorState = false;
    //   control.setErrors({ 'maxEmail': null });
    //   // control.markAsTouched();
    // }

    if (isError) {
      control.markAsTouched();
      return isError;
    }

    // control.setErrors({ 'duplicateEmail': null });
    control.setErrors({ 'incorrectEmail': null });
    control.updateValueAndValidity();

    for (const emailInfo of emailList) {
      if (emailInfo.invalid) {
        this.emailChipList.errorState = true;
        control.setErrors({ 'incorrectEmail': true });
        isError = true;
      }
      if (isError) {
        control.markAsTouched();
        break;
      }
    }
    return isError;

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our email
    if (value) {
      if (this.validateEmail(value)) {
        this.emails.push({ email: value, invalid: false });
      } else {
        this.emails.push({ email: value, invalid: true });
        // constrol.setErrors({ 'incorrectEmail': true });
        // constrol.markAsTouched();
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.validEmails(this.emails);
    // if (event.value) {
    //   if (this.validateEmail(event.value)) {
    //     this.emails.push({ value: event.value, invalid: false });
    //   } else {
    //     this.emailList.push({ value: event.value, invalid: true });
    //     this.rulesForm.controls['emails'].setErrors({ 'incorrectEmail': true });
    //   }
    // }
    // if (event.input) {
    //   event.input.value = '';
    // }

  }

  remove(email: Email): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }

    this.validEmails(this.emails);

  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendRequest() {
    let toMailList: string;
    toMailList = this.data.defaultEmail;
    this.requestForm.disable();
    // const formValue = this.requestForm.value;
    const request = { isDefault: 1, email: toMailList };
    // if (formValue.changeEmail) {
    toMailList = this.emails.map(res => res.email).join(', ');
    request.isDefault = 0;
    request.email = toMailList;
    // }
    this.dialogRef.close(request);
  }
  ngOnDestroy() {
    this.data = null;
  }
}
