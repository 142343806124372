import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CoreModule } from '../core/core.module';
/* components */
import { AlertComponent } from './components/alert/alert.component';
import { CancelMobileChangeRequestComponent } from './components/cancel-mobile-change-request/cancel-mobile-change-request.component';
import { CardComponent } from './components/card/card.component';
import { CustomerProfileComponent } from './components/customer-profile/customer-profile.component';
import { FileTreeComponent } from './components/file-tree/file-tree.component';
import { FilterLogsComponent } from './components/filter-logs/filter-logs.component';
import { FindStoreComponent } from './components/find-store/find-store.component';
import { LocationEditComponent } from './components/location-edit/location-edit.component';
import { MobileNumberChangeComponent } from './components/mobile-number-change/mobile-number-change.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OtpInComponent } from './components/otp-in/otp-in.component';
import { OtpOutComponent } from './components/otp-out/otp-out.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component';
import { CrocsAboutComponent } from './components/static-pages/crocs-about/crocs-about.component';
import { CrocsContactUsComponent } from './components/static-pages/crocs-contact-us/crocs-contact-us.component';
import { CrocsPrivacyPolicyComponent } from './components/static-pages/crocs-privacy-policy/crocs-privacy-policy.component';
import { CrocsQueriesComponent } from './components/static-pages/crocs-queries/crocs-queries.component';
import { CrocsTermsComponent } from './components/static-pages/crocs-terms/crocs-terms.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TabContentComponent } from './components/tabset/tab-content/tab-content.component';
import { TabsetComponent } from './components/tabset/tabset.component';
import { TermsAndConditionsPopupComponent } from './components/terms-and-conditions-popup/terms-and-conditions-popup.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { TodolistComponent } from './components/todolist/todolist.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { UserLogsComponent } from './components/user-logs/user-logs.component';
import { VerifyEmailAddressComponent } from './components/verify-email-address/verify-email-address.component';
import { VerifyOptComponent } from './components/verify-opt/verify-opt.component';
import { PartialsModule } from './partials/partials.module';
import { DownloadReportConfirmDialogComponent } from './components/download-report-confirm-dialog/download-report-confirm-dialog.component';
import { TableFiltersFieldsComponent } from './components/table-filters-fields/table-filters-fields.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    PartialsModule,
    NgxDaterangepickerMd.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDIe6CBuymf60DqeaqW54Ti4mNDPEiIq_U'
    }),
    RouterModule.forChild([])
  ],
  declarations: [
    CardComponent,
    FileTreeComponent,
    TodolistComponent,
    TabsetComponent,
    TabContentComponent,
    ProgressBarComponent,
    SwitchComponent,
    AlertComponent,
    ProfileComponent,
    UserEditComponent,
    ProfileEditComponent,
    UserLogsComponent,
    SpinnerButtonComponent,
    LocationEditComponent,
    MobileNumberChangeComponent,
    CancelMobileChangeRequestComponent,
    VerifyEmailAddressComponent,
    FilterLogsComponent,
    VerifyOptComponent,
    OtpOutComponent,
    TermsAndConditionsComponent,
    OtpInComponent,
    CustomerProfileComponent,
    CrocsQueriesComponent,
    CrocsAboutComponent,
    CrocsTermsComponent,
    CrocsPrivacyPolicyComponent,
    CrocsContactUsComponent,
    FindStoreComponent,
    NotFoundComponent,
    TermsAndConditionsPopupComponent,
    DownloadReportConfirmDialogComponent,
    TableFiltersFieldsComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    PartialsModule,
    NgxDaterangepickerMd,
    CardComponent,
    FileTreeComponent,
    TodolistComponent,
    TabsetComponent,
    TabContentComponent,
    ProgressBarComponent,
    SwitchComponent,
    AlertComponent,
    ProfileComponent,
    UserEditComponent,
    ProfileEditComponent,
    SpinnerButtonComponent,
    LocationEditComponent,
    MobileNumberChangeComponent,
    FilterLogsComponent,
    VerifyOptComponent,
    CustomerProfileComponent,
    CrocsQueriesComponent,
    CrocsAboutComponent,
    CrocsTermsComponent,
    CrocsPrivacyPolicyComponent,
    CrocsContactUsComponent,
    FindStoreComponent,
    NotFoundComponent,
    TermsAndConditionsPopupComponent,
    DownloadReportConfirmDialogComponent,
    TableFiltersFieldsComponent
  ],
  entryComponents: [
    FindStoreComponent
  ]
})
export class SharedModule { }
