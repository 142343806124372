import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-crocs-queries',
  templateUrl: './crocs-queries.component.html',
  styleUrls: ['./crocs-queries.component.scss']
})
export class CrocsQueriesComponent implements OnInit {

  queries: string;
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.queries = "<li>Coming Soon...!!!</li>";
    this.renderer.addClass(this.document.body, 'customer-class');
    this.renderer.addClass(this.document.body, 'no-footer');
  }

}
