import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { MyErrorHandler } from './core/handler/my-error-handler';
import { httpInterceptorProviders } from './core/http-interceptors';
import { AuthService } from './core/services/authentication/auth.service';
import { RouterGuard } from './core/services/authentication/router.gaurd';
import { TokenStorage } from './core/services/authentication/token-storage.service';
import { HttpErrorHandler } from './core/services/http-error-handler.service';
import { MessageService } from './core/services/message.service';
import { TypesUtilsService } from './core/services/types-utils.service';
import { TermsAndConditionsPopupComponent } from './shared/components/terms-and-conditions-popup/terms-and-conditions-popup.component';
import { VerifyOptComponent } from './shared/components/verify-opt/verify-opt.component';
import { CommonService } from './core/services/common.service';
import { environment } from 'src/environments/environment';

export function tokenGetter() {
  return localStorage.getItem(environment.accessTokenParam);
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AuthModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    HttpErrorHandler,
    MessageService,
    httpInterceptorProviders,
    TypesUtilsService,
    TokenStorage,
    RouterGuard,
    AuthService,
    CommonService,
    { provide: ErrorHandler, useClass: MyErrorHandler }
    /* { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, */
  ],
  entryComponents: [
    VerifyOptComponent,
    TermsAndConditionsPopupComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
