import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// import { MatDialog } from '@angular/material';
import { DOCUMENT } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { User } from 'src/app/customer/models/user.model';
import { ManageCustomersService } from 'src/app/merchant/loyalty-management/components/manage-customers/manage-customers.service';
import { UserService } from 'src/app/core/services/user.service';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { ApiResult } from '../../../core/models/api-result';
import { MobileNumberChangeRequest, SearchCustomer } from '../../../core/models/search-customer';
import { AuthService } from '../../../core/services/authentication/auth.service';
import { SharedService } from '../../services/shared.service';
import { TypesUtilsService } from '../../../core/services/types-utils.service';
import { SpinnerButtonOptions } from '../spinner-button/button-options.interface';
import { VerifyOptComponent } from '../verify-opt/verify-opt.component';
import { MobileNumberChangeService } from './mobile-number-change.service';
import { MatDialog } from '@angular/material/dialog';


export interface PendingResponse {
  request_id: number;
  customer_loyalty_id: number;
  existing_mobile_number: string;
  new_mobile_number: string;
}
@Component({
  selector: 'app-mobile-number-change',
  templateUrl: './mobile-number-change.component.html',
  styleUrls: ['./mobile-number-change.component.scss'],
  providers: [SharedService]
})
export class MobileNumberChangeComponent implements OnInit, OnDestroy {
  requestForm: FormGroup;
  otp: string;
  mobnumPattern = '[6-9]\\d{9}';
  subscriptions: Subscription[] = [];
  isValidRequest: boolean;
  isRequested: boolean;
  spinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: '',
    spinnerColor: 'primary',
    fullWidth: false,
    mode: 'indeterminate'
  };
  isLoading: boolean;
  canSendOtp: boolean;
  sameMobile: boolean;
  otpAttempts: boolean;
  otpVerified: boolean;
  @Input() user: User;
  @Output() loadMobileChangeList = new EventEmitter<boolean>();
  checkPendingResponse = {
    next: (result: SearchCustomer) => this.handlePendingResponse(result),
    error: err => this.handleError(err),
    complete: () => this.complete(),
  };

  checkRequestOtpResponse = {
    next: (result: ApiResult) => this.handleRequestOtpResponse(result),
    error: err => this.handleError(err),
    complete: () => this.complete(),
  };

  constructor(
    private _formBuilder: FormBuilder,
    private mobileNumberChangeService: MobileNumberChangeService,
    private authService: AuthService,
    private manageCustomersService: ManageCustomersService,
    private userService: UserService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private typesUtilsService: TypesUtilsService,
    private renderer: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.loadMobileChangeList.emit(true);
    if (this.authService.currentUser.isCustomer) {
      this.renderer.addClass(this.document.body, 'customer-class');
    }


    if (!this.user) {
      this.user = this.authService.currentUser;
      this.user.customer_loyalty_id = this.authService.currentUser.user_id;
    }
    this.checkInputSet();
    // console.log('ngOnInit', this.user);
    //   this.registerForm = this.createForm();

    this.requestForm = this.createRequestForm();
    this.initFormChanges();
  }

  checkInputSet() {
    if (this.user.customer_loyalty_id) {
      this.isValidRequest = true;
      const requestParam = {
        request_type: 2,
        customer_loyalty_id: this.user.customer_loyalty_id
      };

      this.subscriptions.push(
        this.mobileNumberChangeService.checkForPendingRequest(requestParam).subscribe(this.checkPendingResponse)
      );
    }
  }

  handlePendingResponse(result: SearchCustomer) {
    if (!result) {
      return;
    }
    const cForm = this.requestForm;
    const request: MobileNumberChangeRequest = result.mobile_number_change_request;

    if (!Object.keys(request).length) {
      const newMobileControl = cForm.get('new_mobile_number');
      cForm.get('id').setValue(null);
      newMobileControl.setValue('');
      newMobileControl.enable();
      this.isRequested = false;
      return;
    }

    for (const field in request) {
      if (field) {
        this.isRequested = true;
        const fieldControl = cForm.get(field);
        if (fieldControl) {
          fieldControl.setValue(request[field]);
          fieldControl.disable();
        }
      }
    }

  }

  handleError(error: Error) {

  }

  complete() {

  }
  createRequestForm() {
    return this._formBuilder.group({
      request_type: [2],
      existing_mobile_number: [
        { disabled: true, value: this.user.mobile_number },
        [
          Validators.required,
          Validators.pattern(this.mobnumPattern)
        ]
      ],
      new_mobile_number: [
        null,
        [Validators.required, Validators.pattern(this.mobnumPattern)]
      ],
      id: [null]
    });
  }

  setAlert(params: SweetAlertOptions) {
    return this.typesUtilsService.sweetAlert(params);
  }

  cancelRequest() {
    const request = this.requestForm.getRawValue();
    this.setAlert({
      type: 'warning',
      text: 'You want to cancel this request?',
      showCancelButton: true,
    }).then(result => {
      this.registerCancelRequest(result, request);
    });
  }

  registerCancelRequest(result: SweetAlertResult, data: any) {
    if (result.value) {
      this.loadMobileChangeList.emit(false);
      this.subscriptions.push(
        this.sharedService.changeRequestStatus({
          request_type: 2,
          id: data.id,
          customer_loyalty_id: this.user.customer_loyalty_id,
          status: 3
        }).subscribe(res => {
          this.setAlert(res.data).then(alertResult => {
            this.checkInputSet();
            if (alertResult.value) {
              if (this.authService.currentUser.isCustomer) {
                this.router.navigateByUrl('dashboard/profile');
              } else {
                setTimeout(() => {
                  this.loadMobileChangeList.emit(true);
                }, 500);
              }
            }
          });
        })
      );
    }
  }

  inputValidator(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  }

  closeDialog() {
    // this.dialogRef.close();
  }


  isMobileFieldValid(value: any) {
    return value && value.length > 9 && /^[0-9]*$/.test(value);
  }

  mobileValidation(isExist: boolean) {
    const validation = [Validators.pattern('[6-9]\\d{9}'), Validators.required];
    if (isExist) {
      validation.push(this.isExistValidator(isExist));
    }
    return validation;
  }

  isExistValidator(isExist: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (isExist) {
        return { isExist: true };
      }
      return null;
    };
  }
  initFormChanges() {
    const cForm = this.requestForm;
    const mobileControl = this.requestForm.get('new_mobile_number');
    const oldMobileControl = this.requestForm.get('existing_mobile_number');
    this.subscriptions.push(mobileControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(value => {
        this.sameMobile = false;

        if (!this.isMobileFieldValid(value)) {
          mobileControl.setValidators(this.mobileValidation(false));
          mobileControl.updateValueAndValidity();
          this.canSendOtp = false;
        }
      }),
      filter(value => this.isMobileFieldValid(value))
    ).subscribe(result => {
      if (oldMobileControl.value === result) {
        this.sameMobile = true;
        return;
      }
      mobileControl.setValidators(this.mobileValidation(false));
      mobileControl.markAsTouched();
      mobileControl.updateValueAndValidity();
    }));
  }
  submit() {
    this.spinner.active = true;
    const cFrom = this.requestForm;

    const existingControl = cFrom.get('existing_mobile_number');
    const newControl = cFrom.get('new_mobile_number');

    if (existingControl.value === newControl.value) {
      this.sameMobile = true;
      return;
    }
    newControl.disable();
    const requestParam = {
      old_mobile_number: existingControl.value,
      mobile_number: newControl.value,
      type: 202 // for change mobile number
    };
    this.subscriptions.push(this.manageCustomersService
      .checkMobileNumber(requestParam).pipe(
        switchMap(result => {
          if (this.updateFieldValidation(result)) {
            newControl.enable();
            this.spinner.active = false;
            return of();
          }
          // console.log(result);
          return this.userService.requestOtp(requestParam);
        })
      ).subscribe(result => {
        this.spinner.active = false;
        if (!result.data) {
          newControl.enable();
          return;
        }

        if (this.checkOtpResponse(result)) {
          newControl.enable();
          newControl.setErrors({ 'serverError': true, message: result.message });
          newControl.markAsTouched();
          return;
        }
        const dialogData = {
          ...requestParam,
          ...result.data,
          ...cFrom.getRawValue(),
          customer_loyalty_id: this.user.customer_loyalty_id,
          type: 202
        };
        this.callDialog(dialogData);
      }, error => {
        // console.log(error);
        newControl.enable();
        this.spinner.active = false;
      }));

  }

  updateFieldValidation(result: ApiResult) {
    const newControl = this.requestForm.get('new_mobile_number');
    let isRegister: boolean;
    if (result.data.isRegister) {
      newControl.setValidators(this.mobileValidation(true));
      newControl.markAsDirty();
      newControl.markAsTouched();
      newControl.updateValueAndValidity();
      isRegister = true;
    } else {
      isRegister = false;
      newControl.setValidators(this.mobileValidation(false));
    }
    newControl.markAsTouched();
    newControl.updateValueAndValidity();
    return isRegister;
  }

  checkOtpResponse(result: ApiResult) {
    return this.isServerError(result) || this.attemptExeed(result);
  }

  handleRequestOtpResponse(result: ApiResult) {
    if (!result.data) {
      return;
    }

    if (this.checkOtpResponse(result)) {
      return;
    }

    // this.callDialog(result);
  }

  attemptExeed(result: ApiResult) {
    if (result.data['otp_attempt'] >= 3) {
      this.spinner.active = false;
      if (result.data['statusCode'] === 104) {
        this.otp = '';
        this.otpAttempts = true;
      }
      this.setFormError(result);

      return true;
    }
    return false;
  }

  isServerError(result: ApiResult) {
    if (result.data['statusCode'] === 105) {
      this.spinner.active = false;
      this.setFormError(result);
      return true;
    }
    return false;
  }

  setFormError(result: ApiResult) {
    this.requestForm.setErrors({ 'server-error': true, message: result.message });
  }
  callDialog(data) {
    const cFrom = this.requestForm;

    const existingControl = cFrom.get('existing_mobile_number');
    const newControl = cFrom.get('new_mobile_number');
    const dialogRef = this.dialog.open(VerifyOptComponent, {
      autoFocus: true,
      disableClose: true,
      maxWidth: '345px',
      panelClass: 'verify-otp-panel',
      data: data
    });
    dialogRef.afterClosed().pipe(
      switchMap(result => {
        if (!result) {
          newControl.enable();
          return of();
        } else {
          this.loadMobileChangeList.emit(false);
          return this.sharedService.changeRequestFromCustomer(result);
        }
      })
    ).subscribe(result => {
      let alertParams = {};
      if (!result) {
        newControl.enable();
        return;
      } else {
        const errorStatusCode = [102, 104];
        if (errorStatusCode.indexOf(result.data['statusCode']) !== -1) {
          alertParams = { type: 'error', text: result.message };
          newControl.enable();
          // this.setFormError(result);
        }

        if (result.data['statusCode'] === 100) {
          cFrom.get('id').setValue(result.data['lastInsertedId']);
          alertParams = { type: 'success', text: result.message }
          this.isRequested = true;
        }
        this.typesUtilsService.sweetAlert(alertParams);
        setTimeout(() => {
          this.loadMobileChangeList.emit(true);
        }, 500);
        // newControl.enable();

      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
