import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { SafeHtmlPipe } from '../../../../core/pipes/safe-html.pipe';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-crocs-about',
  templateUrl: './crocs-about.component.html',
  styleUrls: ['./crocs-about.component.scss'],
  providers: [SharedService, SafeHtmlPipe]
})
export class CrocsAboutComponent implements OnInit {
  aboutUs: any;
  constructor(
    private shareService: SharedService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.aboutUs = "Loading...!!!";
    this.renderer.addClass(this.document.body, 'customer-class');
    this.renderer.addClass(this.document.body, 'no-footer');
    const params = {
      "page_name": 'about_crocs'
    };
    this.shareService.getStaticPageData(params).subscribe(res => {
      if (res) {
        this.aboutUs = res;
      }
    }, err => {
      // console.log(err);
    });
  }

}
