import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { DownloadReportConfirmDialogComponent } from 'src/app/shared/components/download-report-confirm-dialog/download-report-confirm-dialog.component';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { TableOptions } from '../interfaces/dataTableOptions.iterface';
import { SelectedDate } from '../interfaces/selected-date.interface';
import { TokenStorage } from './authentication/token-storage.service';

@Injectable()
export class TypesUtilsService {
  private currentDate = new Date();
  private currentYear = this.currentDate.getFullYear();
  private currentMonth = this.currentDate.getMonth();
  dialogRef: any;
  private startSystemDate = '2019-06-13';
  constructor(
    @Inject(LOCALE_ID) private local: string,
    public _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private tokenStorage: TokenStorage) { }

  transformDate(date: Date = new Date(), dateFormat: string = 'yyyy/MM/dd') {
    return formatDate(date, dateFormat, this.local);
  }

  padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  dateFormat(date: Date): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    if (date) {
      return `${month}/${day}/${year}`;
    }

    return '';
  }

  dateCustomFormat(date: any): string {
    let stringDate: string = '';
    if (date) {
      stringDate += this.isNumber(date.month)
        ? this.padNumber(date.month) + "/"
        : '';
      stringDate += this.isNumber(date.day)
        ? this.padNumber(date.day) + "/"
        : '';

      stringDate += date.year;
    }
    return stringDate;
  }

  getDateFormatterFromString(dateInStr: string): any {
    if (dateInStr && dateInStr.length > 0) {
      const dateParts = dateInStr.trim().split("/");
      return [
        {
          year: this.toInteger(dateParts[2]),
          month: this.toInteger(dateParts[0]),
          day: this.toInteger(dateParts[1])
        }
      ];
    }

    const _date = new Date();
    return [
      {
        year: _date.getFullYear(),
        month: _date.getMonth() + 1,
        day: _date.getDay()
      }
    ];
  }

  getLastDay() {
    const date = new Date();
    return new Date(date.setDate(date.getDate() - 1));
    // return this.transformDate(date, 'yyyy/MM/dd');
  }
  getDateFromString(dateInStr: string = ""): Date {
    if (dateInStr && dateInStr.length > 0) {
      const dateParts = dateInStr.trim().split("/");
      const year = this.toInteger(dateParts[2]);
      const month = this.toInteger(dateParts[0]);
      const day = this.toInteger(dateParts[1]);
      // tslint:disable-next-line:prefer-const
      let result = new Date();
      result.setDate(day);
      result.setMonth(month - 1);
      result.setFullYear(year);
      return result;
    }

    return new Date();
  }

  getDateFromStringYMD(dateInStr: string = ""): Date {
    if (dateInStr && dateInStr.length > 0) {
      const dateParts = dateInStr.trim().split("/");
      const year = this.toInteger(dateParts[0]);
      const month = this.toInteger(dateParts[1]);
      const day = this.toInteger(dateParts[2]);
      // tslint:disable-next-line:prefer-const
      let result = new Date();
      result.setDate(day);
      result.setMonth(month - 1);
      result.setFullYear(year);
      return result;
    }

    return new Date();
  }

  getDateStringFromDate(_date: Date = new Date()): string {
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const date = _date.getDate();
    return `${month}/${date}/${year}`;
  }

  getDateYMD(dateInStr: string = ""): string {
    if (dateInStr && dateInStr.length > 0) {
      const dateParts = dateInStr.trim().split("/");
      const year = dateParts[2];
      const month = dateParts[1];
      const day = dateParts[0];
      // tslint:disable-next-line:prefer-const

      return `${year}/${month}/${day}`;
    }
    return "";
  }
  getDateDMY(dateInStr: string = ""): string {
    if (dateInStr && dateInStr.length > 0) {
      const dateParts = dateInStr.trim().split("/");
      const year = dateParts[2];
      const month = dateParts[1];
      const day = dateParts[0];
      // tslint:disable-next-line:prefer-const

      return `${day}/${month}/${year}`;
    }
    return "";
  }

  getLocalDate(_date: Date = new Date()): string {
    let strDate = _date.toDateString();
    let result = new Date(strDate);
    const month = this.getParseMonth(result);
    const year = result.getFullYear();
    const date = this.getParseDate(result);
    return `${year}/${month}/${date}`;
  }

  getParseDate(d) {
    return (d.getDate() < 10 ? "0" : "") + d.getDate();
  }

  getParseMonth(d) {
    return (d.getMonth() + 1 < 10 ? "0" : "") + (d.getMonth() + 1);
  }
  setMinYearDate(_date: Date = new Date(), year: number = 1) {
    return new Date(_date.getFullYear() - year, _date.getMonth(), _date.getDate());
  }

  randomString() {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    const string_length = 8;
    let randomstring = '';
    for (let i = 0; i < string_length; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  camelize(str) {
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }

  sweetAlert(params: SweetAlertOptions): Promise<SweetAlertResult> {
    params = {
      type: 'error',
      text: 'Something went wrong!',
      cancelButtonColor: '#d33',
      ...params,
    };
    return Swal.fire(params);
  }

  snackbarAlert(message, isError?: boolean, options?: MatSnackBarConfig) {
    const defaultConfig: MatSnackBarConfig = {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'blue-snackbar'
    };

    if (isError) {
      options = {
        panelClass: 'red-snackbar',
        horizontalPosition: 'right',
        ...options
      };
    }

    const updatedConfig = { ...defaultConfig, ...options };

    setTimeout(() => {
      this._snackBar.open(message, '', updatedConfig);
    }, 0);
  }

  get startDate() {
    return new Date(this.currentYear, this.currentMonth, this.currentDate.getDate() - 30);
  }

  get endDate() {
    return new Date(this.currentYear, this.currentMonth, this.currentDate.getDate());
  }

  get datePickerRange() {
    return {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      // 'Quarterly': [moment().startOf('month'), moment().quarters(3)],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //'Life Time': [moment(this.startSystemDate), moment().add(0, 'day')]
    };
  }

  get invalidDates(): moment.Moment[] {
    return [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'));
  }

  tablePaginatorOptions(): TableOptions {
    return {
      pageIndex: 0,
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
      showFirstLastButtons: true
    };

  }

  get selectedDate(): SelectedDate {
    return {
      from_date: this.transformDate(this.startDate, 'yyyy-MM-dd'),
      to_date: this.transformDate(this.endDate, 'yyyy-MM-dd'),
      startDate: this.startDate,
      endDate: this.endDate
    };
  }

  get maxDate(): moment.Moment {
    return moment().add(0, 'day');
  }
  get minDate(): moment.Moment {
    return moment(this.startSystemDate);
  }

  get minMaxDatePicker() {
    return {
      minDate: new Date(this.startSystemDate),
      maxDate: new Date(),
    };
  }
  parseJson(data: string) {
    try {
      return JSON.parse(data);
    } catch (err) {
      // console.error(err);
      return null;
    }
  }

  get dateLimit(): number {
    return 90;
  }

  downloadConfirmOpen(): MatDialogRef<DownloadReportConfirmDialogComponent, any> {
    const userInfo = this.tokenStorage.decodeUserFromToken();
    let email = null;
    if (userInfo) {
      email = userInfo.email;
    }

    return this.dialog.open(DownloadReportConfirmDialogComponent, {
      autoFocus: true,
      disableClose: true,
      width: '600px',
      // height: '300px',
      panelClass: 'delete-dialog',
      data: { defaultEmail: email }
    });

  }

  downloadConfirmAfterClosed(dialogRef) {
    return dialogRef.afterClosed();
    // .subscribe(res => {
    //   if (!res) {
    //     return;
    //   } else {
    //     console.log('downloadConfirmAfterClosed', res);
    //   }
    // });
  }

}
