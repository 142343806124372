import { Directive, AfterViewInit, OnDestroy, ElementRef, HostListener, OnInit, Input, HostBinding } from '@angular/core';
// import { LayoutRefService } from '../services/layout/layout-ref.service';
import { Router } from '@angular/router';
import * as objectPath from 'object-path';
// import { LayoutConfigService } from '../services/layout-config.service';
import { combineLatest } from 'rxjs';

interface PortletOptions {
  enableSticky?: boolean;
  headOverflay?: boolean;
  headLarge?: boolean;
  class?: string[];
}

@Directive({
  selector: '[mPortlet]'
})

export class PortletDirective implements AfterViewInit, OnDestroy, OnInit {
  portlet: any;

  @Input() options: PortletOptions;
  @HostBinding('class') class: any;
  /*  @HostListener('window:resize', ['$event'])
   onResize(event) {
     if (this.portlet instanceof mPortlet && objectPath.get(this.options, 'enableSticky')) {
       this.portlet.updateSticky();
     }
   } */

  constructor(private el: ElementRef) {
    this.class = this.el.nativeElement.classList;
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    const cls = objectPath.get(this.options, 'class');
    if (Array.isArray(cls)) {
      cls.forEach((c) => {
        this.class.add(c);
      });
    } else if (cls) {
      this.class.add(cls);
    }

    if (objectPath.get(this.options, 'headOverlay')) {
      this.class.add('m-portlet--head-overlay');
    }

    if (objectPath.get(this.options, 'headLarge')) {
      this.class.add('m-portlet--head-lg');
    }
  }

  ngOnDestroy(): void {
  }
}
