import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TypesUtilsService } from '../../../core/services/types-utils.service';
import * as moment from 'moment';


@Component({
  selector: 'app-filter-logs',
  templateUrl: './filter-logs.component.html',
  styleUrls: ['./filter-logs.component.scss']
})
export class FilterLogsComponent implements OnInit, OnDestroy {

  @Input('formControls') formControls: any;
  @Output('submited') userSubmited = new EventEmitter();
  filterForm: FormGroup;
  old_field: any;
  customer_name: any;
  from_date: any;
  to_date: any;
  minDate: Date = new Date();
  mobileFilter: boolean;

  single: any[];

  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Quarterly': [moment().startOf('month'), moment().quarters(3)],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }
  transReport: boolean = false;
  homeBranch: boolean = false;
  regReport: boolean = false;
  voucherReport: boolean;
  campaignRoi: boolean = false;
  feedbackList: boolean = false;
  startDate: any;
  endDate: any;

  constructor(
    private _formBuilder: FormBuilder,
    private typesUtilsService: TypesUtilsService,
  ) { }

  ngOnInit() {
    switch (this.formControls) {
      case 'mobile_change':
        this.mobileFilter = true;
        break;
      case 'home_branch_change':
        this.homeBranch = true;
        break;
      case 'trans_report':
        this.transReport = true;
        break;
      case 'registration-reports':
        this.regReport = true;
        break;
      case 'voucher-reports':
        this.voucherReport = true;
        break;
      case 'campaign-roi':
        this.campaignRoi = true;
        break;
      case 'feedback-list':
        this.feedbackList = true;
        break;
      default:
        break;
    }
    // set start date and end date
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    this.startDate = new Date(y, m - 1, 1);
    this.endDate = new Date(y, m, 0);
    this.createForm();
  }

  createForm() {
    this.filterForm = this._formBuilder.group({
      dateCalendar: (this.campaignRoi) ? [{ startDate: this.startDate, endDate: this.endDate }] : [''],
      from_date: [''],
      to_date: [''],
      customer_name: [
        null,
        Validators.compose([
          //    Validators.pattern(/^[a-zA-Z ]{1,20}$/)
        ])
      ],
      mobile_number: [
        null,
        [
          Validators.compose([
            Validators.pattern('[6-9](?!00000000)\\d{9}'),
            // Validators.pattern(/^[0-9]\d{7,14}$/),
            Validators.minLength(8)
          ])
        ]
      ],
      order_number: [
        null,
        Validators.compose([
          Validators.pattern(/^[a-zA-Z0-9]{1,20}$/)
        ])
      ],
      voucher_code: [
        null,
        Validators.compose([
          Validators.pattern(/^[a-zA-Z0-9]{1,20}$/)
        ])
      ],
      voucher_name: [
        null,
        Validators.compose([
          Validators.pattern(/^[a-zA-Z0-9 ]{1,20}$/)
        ])
      ],
      existing_mobile_number: [
        '',
        Validators.compose([
          Validators.pattern('[6-9]\\d{9}')
        ])
      ],
      existing_home_branch: [
        '',
        Validators.compose([
          Validators.pattern(/^[a-zA-Z ]{1,20}$/)
        ])
      ],
    });
  }
  inputValidator(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }

  }
  change(event) {
    const startDateControl = this.filterForm.get('from_date');
    const endDateControl = this.filterForm.get('to_date');
    if (event.startDate != null) {
      this.startDate = new Date(event.startDate);
      this.endDate = new Date(event.endDate);
      startDateControl.setValue(this.typesUtilsService.transformDate(this.startDate, 'yyyy-MM-dd'));
      endDateControl.setValue(this.typesUtilsService.transformDate(this.endDate, 'yyyy-MM-dd'));
    } else {
      this.startDate = null;
      this.endDate = null;
      startDateControl.setValue(null);
      endDateControl.setValue(null);
    }
    startDateControl.updateValueAndValidity();
    endDateControl.updateValueAndValidity();
  }

  submited() {
    const controls = this.filterForm.controls;
    const formValues: any = {
      existing_mobile_number: '',
      existing_home_branch: '',
      customer_name: '',
      from_date: '',
      mobile_number: '',
      order_number: '',
      to_date: '',
      voucher_code: '',
      voucher_name: ''
    };
    /** check form */
    const dateColums = ['from_date', 'to_date'];
    Object.keys(controls).forEach(controlName => {
      const controlValue = controls[controlName].value;
      if (controlValue instanceof Date || (dateColums.indexOf(controlName) !== -1 && controlValue)) {
        formValues[controlName] = this.typesUtilsService.transformDate(controls[controlName].value, 'yyyy-MM-dd');
      } else {
        formValues[controlName] = controls[controlName].value;
      }
    });
    delete formValues['dateCalendar'];
    this.userSubmited.emit(formValues);
  }

  clearFilter() {
    this.filterForm.reset();
    this.filterForm.updateValueAndValidity();
    this.submited();
  }

  ngOnDestroy() {
    this.filterForm.reset();
    if(this.campaignRoi) {
      const filters = {
        "from_date": this.typesUtilsService.transformDate(this.startDate, 'yyyy-MM-dd'),
        "to_date": this.typesUtilsService.transformDate(this.endDate, 'yyyy-MM-dd')
      };
      this.userSubmited.emit(filters);
    } else {
      this.userSubmited.emit({});
    }
  }

}
