import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { IpService } from 'src/app/core/services/ip.service';
import { ApiResult } from '../../core/models/api-result';

@Injectable()
export class SharedService {

  constructor(private ipService: IpService,private authService:AuthService) { }

  changeRequestFromCustomer(param): Observable<ApiResult> {
    return this.ipService.post('api/merchant-customers/change-request-from-customer', param);
  }

  changeRequestStatus(params) {
    return this.ipService.put('api/merchant-customers/changeRequestStatus', params);
  }
  getStaticPageData(params) {
    return this.ipService.post('api/shared-controller/getStaticPages', params).pipe(
      map( res => {
        if (!res || !res.data) {
          this.authService.alertToUser('Something went wrong while Sub-Merchant list loading', 'red-snackbar');
        } else {
          return res.data;
        }
      })
    );
  }
}
