import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'unescapeData'
})
export class UnescapeDataPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return _.unescape(value);
  }

}
