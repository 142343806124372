import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/customer/models/user.model';
import { IpService } from 'src/app/core/services/ip.service';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileEditService {
  public form: FormGroup;
  public user: User;
  isMobileDisable: boolean;
  // emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\\.]+)\.([a-zA-Z]{2,5})$/;
  constructor(private _formBuilder: FormBuilder,
    private ipService: IpService,
    private authService: AuthService) {
    this.user = new User();
    // this.form = this.initForm();
  }

  initForm() {
    return this._formBuilder.group({
      first_name: [this.user.first_name],
      last_name: [this.user.last_name],
      email_address: [this.user.email_address],
      mobile_number: [{ disabled: this.isMobileDisable, value: this.user.mobile_number }],
      gender: [this.user.gender],
      date_of_birth: [this.user.date_of_birth],
      marital_status: [this.user.marital_status],
      anniversary_date: [this.user.anniversary_date || null],
      spouse_dob: [this.user.spouse_dob || null],
      otp: [this.user.otp || null],
      pin_code: [this.user.pin_code],
      city_name: [this.user.city_name]
    });
  }

  fieldList() {
    return [
      'first_name',
      'last_name',
      'email_address',
      'mobile_number',
      'gender',
      'date_of_birth',
      'marital_status',
      'anniversary_date',
      'spouse_dob',
      'otp',
      'pin_code',
      'city_name',
      'city_id',
      'terms_conditions'
    ];
  }

  fieldValidations() {
    return [
      {
        key: 'first_name',
        // validation: [{ 'type': 'required' }, { 'type': 'pattern', value: /^[a-zA-Z]+$/ }]
        validation: [Validators.pattern(/^[a-zA-Z_ ]+$/), Validators.minLength(3), Validators.maxLength(30)]
      },
      {
        key: 'last_name',
        // validation: [{ 'type': 'required' }, { 'type': 'pattern', value: /^[a-zA-Z]+$/ }]
        validation: [Validators.pattern(/^[a-zA-Z_ ]+$/), Validators.minLength(3), Validators.maxLength(30)]
      },
      {
        key: 'email_address',
        // validation: [{ 'type': 'required' }, { type: 'email' }, { 'type': 'pattern', value: this.emailPattern }]
        validation: [Validators.email, Validators.pattern(this.emailPattern)]
      },
      {
        key: 'mobile_number',
        // validation: [{ 'type': 'required' }, { 'type': 'pattern', value: '[7-9]\\d{9}' }]
        validation: [Validators.pattern('[6-9]\\d{9}')]
      },
      {
        key: 'gender',
        // validation: [{ 'type': 'required' }]
        validation: []
      },
      {
        key: 'date_of_birth',
        // validation: [{ 'type': 'required' }]
        validation: []
      },
      {
        key: 'otp',
        validation: [Validators.minLength(6)]
      },
      {
        key: 'anniversary_date',
        validation: []
      },
      {
        key: 'marital_status',
        validation: []
      },
      {
        key: 'spouse_dob',
        validation: []
      },
      {
        key: 'pin_code',
        validation: [Validators.minLength(3), Validators.maxLength(6)]
      },
      {
        key: 'city_name',
        validation: []
      },
      {
        key: 'city_id',
        validation: []
      },
      {
        key: 'terms_conditions',
        validation: []
      }
    ];
  }

  requiredField(isPosUser: boolean, isEdit?: boolean) {
    let requiredField = ['first_name', 'last_name', 'email_address', 'mobile_number', 'gender', 'date_of_birth', 'pin_code', 'city_name'];
    // && !isEdit
    if (isPosUser) {
      requiredField = ['first_name', 'mobile_number', 'gender'];
    }
    return requiredField;
  }

  getPinCodes(searchData) {
    return this.ipService.post('api/shared-controller/getPinCodes', searchData)
      .pipe(
        map(res => {
          if (!res || !res.data) {
            this.authService.alertToUser('Something went wrong while Sub Merchant list loading', 'red-snackbar');
          } else {
            return res.data;
          }
        })
      );

  }
}
