import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication/auth.service';

@Component({
  selector: 'page-top-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  userProfile: boolean = true;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    if(this.authService.currentUser.isCustomer)
    {
      this.userProfile = false;
    }
  }

}
