import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
//import { OptOutService } from './opt-out.service';
import { DOCUMENT } from '@angular/common';
import { OptOutService } from '../otp-out/opt-out.service';
import { AuthService } from 'src/app/core/services/authentication/auth.service';

@Component({
  selector: 'app-otp-in',
  templateUrl: './otp-in.component.html',
  styleUrls: ['./otp-in.component.scss']
})
export class OtpInComponent implements OnInit {
  data: any;
  idObject: any;
  subscriptions: Subscription[] = [];
  message: string;
  success: boolean;
  emailSmsType: any;
  isSearchInProgress: boolean;
  isOverlayVisible: boolean = false;
  idObject1: any;
  disabledButton: boolean;
  smsEmailTitle: string;
  constructor(private route: ActivatedRoute,
    private optOutService: OptOutService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, private authService: AuthService) { }

  ngOnInit() {
    try {
      this.renderer.addClass(this.document.body, 'customer-class');
      this.data = this.route.snapshot.queryParams['q'];
      var base64Url = this.data.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      this.idObject = JSON.parse(window.atob(base64));
      const type = this.idObject.type;
      if (type === 1) {
        this.smsEmailTitle = 'SMS';
      } else if (type === 2) {
        this.smsEmailTitle = 'EMAIL';
      }
    } catch (err) {
      console.error(err);
    }
  }

  subscribe() {
    this.isOverlayVisible = !this.isOverlayVisible
    try {
      var base64Url = this.data.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      this.idObject = JSON.parse(window.atob(base64));
      this.emailSmsType = this.idObject.type;
      //verify email address
      this.isSearchInProgress = true;
      let sendData = {
        "email_address": this.idObject.email_address,
        "mobile_number": this.idObject.mobile_number,
        "first_name": this.idObject.first_name,
        "last_name": this.idObject.last_name,
        "type": this.idObject.type,
      }
      this.subscriptions.push(this.optOutService.optIn(sendData).subscribe(
        result => {
          if (result && result.statusCode == 200) {
            this.isSearchInProgress = false;
            this.isOverlayVisible = false;
            this.authService.swalFireFunction('Subscribe', 'You are subscribed', 'success').then(
              result => {
                if (result.value) {
                  this.disabledButton = true;
                }
              }
            );
          }
          else {
            this.isSearchInProgress = false;
            this.isOverlayVisible = false;
            this.authService.swalFireFunction('Subscribe', 'You can\'t subscribe', 'error')
          }
        }, err => {
          this.isSearchInProgress = false;
          this.isOverlayVisible = false;
          this.authService.swalFireFunction('Subscribe', 'You can\'t subscribe', 'error')
          // console.log(err);
        }
      )
      );
    } catch (err) {
      this.message = "Email couldn't be verified";
      // console.log(err);
    }
  }
}

