import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegisterComponent } from 'src/app/auth/components/register/register.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { NotificationComponent } from '../components/notification/notification.component';
import { GlobalService } from '../services/global.service';
import { SharedModule } from '../shared.module';
import { AuthLandingComponent } from './auth-landing/auth-landing.component';
import { ContentTopComponent } from './content-top/content-top.component';
import { FrontPagesTopComponent } from './front-pages-top/front-pages-top.component';
import { MenuComponent } from './menu/menu.component';
import { PagesTopComponent } from './pages-top/pages-top.component';
import { UserProfileComponent } from './pages-top/user-profile/user-profile.component';
import { RightConfigComponent } from './right-config/right-config.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminPagesTopComponent } from './admin/admin-pages-top/admin-pages-top.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([])
  ],
  providers: [
    GlobalService,
  ],
  declarations: [
    MenuComponent,
    SidebarComponent,
    PagesTopComponent,
    ContentTopComponent,
    NotificationComponent,
    RightConfigComponent,
    LoadingComponent,
    FrontPagesTopComponent,
    UserProfileComponent,
    AuthLandingComponent,
    RegisterComponent,
    AdminPagesTopComponent,
    ScrollTopComponent
  ],
  exports: [
    SidebarComponent,
    PagesTopComponent,
    ContentTopComponent,
    NotificationComponent,
    RightConfigComponent,
    LoadingComponent,
    FrontPagesTopComponent,
    UserProfileComponent,
    AuthLandingComponent,
    RegisterComponent,
    AdminPagesTopComponent,
    ScrollTopComponent
  ]
})
export class LayoutModule { }
