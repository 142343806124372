import {
    ICampaign,
    IBasicDetails,
    ICouponDesign,
    IRewardDetails,
    ICouponDetails,
    IFilter,
    IApplicability,
    IAudienceSelection,
    IExpiryReminders,
    INotifications
} from './campaign.interface';

export class CampaignDataSourceClass {
    ev: string;
    value: any;
}

export interface Deserializable {
    deserialize(input: any): this;
}

export class BasicDetails {
    campaign_type: any;
    campaign_objective: any;
    campaign_name: any;
    campaign_start_date: any;
    is_until_canceled: boolean;
    campaign_end_date?: any;
    terms_and_condition: any;

    deserialize?(input: IBasicDetails) {
        return Object.assign(this, input);
    }
}

export class CouponDetails {
    type_of_coupons: any;
    coupon_format: any;
    coupon_length: any;
    coupon_preffix: any;
    coupon_suffix: any;
    coupon_usage: any;
    coupon_quantity_reminders: any;

    deserialize?(input: ICouponDetails) {
        return Object.assign(this, input);
    }
}

export class RewardDetails {
    type_of_reward: any;
    benifits_value: any;
    benifits_validity: any;
    coupon_required: any;
    coupon_details: CouponDetails = new CouponDetails();

    deserialize?(input: IRewardDetails) {
        Object.assign(this, input);
        this.coupon_details = new CouponDetails().deserialize(input.coupon_details);
        return this;
    }
}

export class Filter {
    data: any;
    kpi: any;
    operator: any;
    value: any;
    order: any;

    deserialize?(input: IFilter) {
        return Object.assign(this, input);
    }
}

export class Applicability {
    applicable_for: any;
    applicable_at: any;
    stores?: any;
    deserialize?(input: IApplicability) {
        return Object.assign(this, input);
    }
}

export class AudienceSelection {
    dataset: any;
    filters: Filter[] = [];
    applicability: Applicability = new Applicability();

    deserialize?(input: IAudienceSelection) {
        Object.assign(this, input);
        this.applicability = new Applicability().deserialize(input.applicability);
        return this;
    }
}

export class CouponDesign {
    card_type: any;
    image?: File;
    title?: string;
    sub_title?: string;
    description?: string;
    card_image_name?: string;
    card_image_path?: string;
    background_color?: string;
    font_color?: string;
    logo_name?: string;
    logo_path?: string;
    deserialize?(input: ICouponDesign) {
        return Object.assign(this, input);
    }
}

export class ExpiryReminders {
    number_of_reminders: any;
    reminders: number[];

    deserialize?(input: IExpiryReminders) {
        return Object.assign(this, input);
    }
}
export class Notifications {
    type_of_notifiction: any;
    expiry_reminders: ExpiryReminders = new ExpiryReminders();

    deserialize?(input: INotifications) {
        Object.assign(this, input);
        this.expiry_reminders = new ExpiryReminders().deserialize(input.expiry_reminders);
        return this;
    }
}

export class CampaignClass {
    basic_details: BasicDetails = new BasicDetails();
    reward_details: RewardDetails = new RewardDetails();
    audience_selection: AudienceSelection = new AudienceSelection();
    coupon_design?: CouponDesign = new CouponDesign();
    notifications: Notifications = new Notifications();

    deserialize?(input: ICampaign) {
        // return Object.assign(this, input);
        // Object.assign(this, input);
        // this.audience_selection.filters = input.map(filter => new Filter().deserialize(filter));
        this.basic_details = new BasicDetails().deserialize(input.basic_details);
        this.reward_details = new RewardDetails().deserialize(input.reward_details);
        this.audience_selection = new AudienceSelection().deserialize(input.audience_selection);
        this.coupon_design = new CouponDesign().deserialize(input.coupon_design);
        this.notifications = new Notifications().deserialize(input.notifications);
        return this;
    }
}

export interface State {
    name: string;
    id: number;
}

export interface City {
    name?: number;
    city_name: string;
    id: number;
}

export interface Store {
    // state_name: number;
    // city_name: number;
    id: number;
    location_name: string;
    store_code: string;
}

export interface ISelectOption {
    id: number;
    name: string;
    type?: string;
    options?: string;
    parent?: number;
}

export interface IStepData {
    [key: string]: {
        id: number;
        name: string;
        operators: ISelectOption[];
        additional_filters: ISelectOption[];
    }[];
}


export interface IStepper {
    [key: string]: any;
}
