import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from 'util';

@Pipe({
  name: 'abbrevateNumber'
})
export class AbbrevateNumberPipe implements PipeTransform {
  SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
  transform(value: any, args?: any): any {

    if (isNull(value) || isNaN(value)) { return 'N/A'; }
    // what tier? (determines SI symbol)
    const tier = Math.log10(value) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return value;

    // get suffix and determine scale
    const suffix = this.SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the value
    const scaled = value / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

}
