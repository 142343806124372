import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/core/services/customer.service';
@Component({
  selector: 'app-find-store',
  templateUrl: './find-store.component.html',
  styleUrls: ['./find-store.component.scss']
})
export class FindStoreComponent implements OnInit {
  order: any;
  latitude: number;
  longitude: number;
  latLongArray: any = [];
  zoomLevel: number;

  constructor(
    private activatedRouter: ActivatedRoute,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    const isMapLoad = this.customerService.setIsMapLoad(true);
    const localStorageData = this.customerService.getLatLongData();

    if (localStorageData.viewFor == 'single') {
      this.zoomLevel = 13;
      this.latitude = parseFloat(localStorageData.latitude);
      this.longitude = parseFloat(localStorageData.longitude);
      this.latLongArray.push({ latitude: this.latitude, longitude: this.longitude });
    } else {
      this.zoomLevel = 9;
      for (var element of localStorageData) {
        this.zoomLevel = 9;
        this.latitude = parseFloat(element.latitude);
        this.longitude = parseFloat(element.longitude);
        this.latLongArray.push({ latitude: this.latitude, longitude: this.longitude });
      }
    }
  }

}
