import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/customer/models/user.model';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { ApiResult } from '../../../core/models/api-result';
import { HomeBranchChangeRequest, SearchCustomer } from '../../../core/models/search-customer';
import { CapsLettersPipe } from '../../../core/pipes/caps-letters.pipe';
import { TypesUtilsService } from '../../../core/services/types-utils.service';
import { SharedService } from '../../services/shared.service';
import { MobileNumberChangeService } from '../mobile-number-change/mobile-number-change.service';
import { SpinnerButtonOptions } from '../spinner-button/button-options.interface';
import { VerifyOptComponent } from '../verify-opt/verify-opt.component';
import { Branch, City, State } from './location-model';
import { LocationService } from './location.service';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss'],
  providers: [SharedService, CapsLettersPipe]
})
export class LocationEditComponent implements OnInit, OnDestroy {
  location; //: Location;
  branchForm: FormGroup;
  @Input() user: User;
  isRequested: boolean;
  mobnumPattern = '[7-9]\\d{9}';
  spinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: '',
    spinnerColor: 'primary',
    fullWidth: false,
    mode: 'indeterminate'
  };

  subMerchantDisplay: Boolean = true;
  states: State[] = [];
  cities: City[] = [];
  branches: Branch[] = [];
  subMerchantsArray: any = [];
  subMerchantsLocationsArray: any;

  isLoading: boolean;

  isValidRequest: boolean;
  checkPendingResponse = {
    next: (result: SearchCustomer) => this.handlePendingResponse(result),
    error: err => this.handleError(err, 'checkForPendingRequest'),
    complete: () => this.complete(),
  };

  checkMerchantLocationResponse = {
    next: (result) => this.handleMerchantLocationResponse(result),
    error: err => this.handleError(err, 'getMerchantLocations'),
    complete: () => this.complete(),
  };

  existing_home_branch: HomeBranchChangeRequest;
  home_branch_change_request: HomeBranchChangeRequest;
  canChangeBranch: boolean;
  subscriptions: Subscription[] = [];
  sameBranch: boolean;
  filteredStates: Observable<State[]>;
  filteredCities: Observable<City[]>;
  filteredBranches: Observable<Branch[]>;
  otpAttempts: boolean;
  notFound: boolean;
  emptyFiltered: Observable<City[]>;
  capsLetter: CapsLettersPipe;
  @Output() homeBranchChangeList = new EventEmitter<boolean>();
  constructor(
    private userService: UserService,
    private locationService: LocationService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private mobileNumberChangeService: MobileNumberChangeService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private typesUtilsService: TypesUtilsService) { }

  ngOnInit() {
    this.homeBranchChangeList.emit(true);
    if (!this.user) {
      this.user = this.authService.currentUser;
      this.user.customer_loyalty_id = this.authService.currentUser.user_id;
    }

    this.checkInputSet();
    this.branchForm = this.createForm();
    this.initFormChanges();
  }

  checkInputSet() {
    if (this.user.customer_loyalty_id) {
      this.isValidRequest = true;
      const requestParam = {
        request_type: 3,
        customer_loyalty_id: this.user.customer_loyalty_id
      };

      this.subscriptions.push(
        this.mobileNumberChangeService.checkForPendingRequest(requestParam).subscribe(this.checkPendingResponse)
      );
    }
  }

  handleMerchantLocationResponse(result) {
    this.subMerchantsLocationsArray = result;
  }

  handleError(error: Error, call: string) {
    // console.log(call, error);
  }

  complete() {

  }

  changeBranch() {
    this.canChangeBranch = !this.canChangeBranch;
    this.resetForm();
    this.setExistingBranch();
  }

  createForm() {
    return this._formBuilder.group({
      request_type: [3],
      // sub_merchant_id: [this.location.sub_merchant_id, [Validators.required]],
      state_name: [null, [
        // Validators.pattern('/^[a-zA-Z]+$/')
      ]],
      city_name: [null, [
        Validators.required,
        Validators.minLength(3),
        // Validators.pattern('/^[a-zA-Z]+$/')
      ]],
      new_home_branch_id: [null, [Validators.required]],
      customer_loyalty_id: [this.user.customer_loyalty_id],
      existing_home_branch_id: [null],
      mobile_number: [this.user.mobile_number]
    });
  }

  initFormChanges() {
    const branchForm = this.branchForm;
    const stateControl = branchForm.get('state_name');
    const cityControl = branchForm.get('city_name');
    /*   this.subscriptions.push(stateControl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((value) => value && stateControl.valid),
          tap(() => {
            this.isLoading = true;
            this.resetFields();
          }),
          switchMap(value => {
            const stateValue = { 'state_name': value };
            return this.getState(stateValue);
          })
        ).subscribe((result) => {
          if (!result.stateList.length) {
            stateControl.setErrors({ 'serverError': true, message: result.message });
            stateControl.markAsTouched();
            return;
          }
          this.filteredStates = result.stateList;
        })); */

    this.subscriptions.push(cityControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((value) => value && cityControl.valid),
        tap(() => {
          this.isLoading = true;
          this.resetFields('city_name');
        }),
        switchMap(value => {
          /*  const stateValue = branchForm.get('state_name').value;
           if (!stateValue) {
             stateControl.markAsTouched();
             return of();
           } */
          const cityValue = { 'city_name': value };
          return this.locationService.getCity(cityValue).pipe(finalize(() => (this.isLoading = false)));
        })
      ).subscribe(result => {
        if (!result.cityList.length) {
          cityControl.setErrors({ 'serverError': true, message: result.message });
          cityControl.markAsTouched();
          return;
        }
        this.filteredCities = result.cityList;
        if (this.filteredCities) {
          if (result.cityList.length == 0) {
            this.notFound = true;
            // var element = <HTMLElement>document.querySelector('.cdk-overlay-container');
            // element.setAttribute('style', 'pointer-events: none!important');
          }
          else {
            this.notFound = false;
            // var element = <HTMLElement>document.querySelector('.cdk-overlay-container');
            // element.setAttribute('style', 'pointer-events:inherit!important');
          }
        }
      }));
  }

  resetFields(currentField = null) {
    const branchForm = this.branchForm;
    // const stateControl = branchForm.get('state_name');
    const cityControl = branchForm.get('city_name');
    const newBranchControl = branchForm.get('new_home_branch_id');
    if (currentField !== 'city_name') {
      cityControl.reset();
      this.filteredCities = null;

    }
    if (newBranchControl.touched) {
      newBranchControl.reset();
      this.subMerchantsLocationsArray = [];
    }
    this.sameBranch = false;

  }

  setAlert(params: SweetAlertOptions) {
    return this.typesUtilsService.sweetAlert(params);
  }

  cancelRequest(request: HomeBranchChangeRequest) {
    this.setAlert({
      type: 'warning',
      text: 'You want to cancel this request?',
      showCancelButton: true,
    }).then(result => this.registerCancelRequest(result, request));
  }

  registerCancelRequest(result: SweetAlertResult, data: HomeBranchChangeRequest) {
    if (result.value) {
      this.homeBranchChangeList.emit(false);
      this.subscriptions.push(
        this.sharedService.changeRequestStatus({
          request_type: 3,
          id: data.id,
          customer_loyalty_id: this.user.customer_loyalty_id,
          status: 3
        }).subscribe(res => {
          this.setAlert(res.data).then(alertResult => {
              this.checkInputSet();
          });
          setTimeout(() => {
            this.homeBranchChangeList.emit(true);
            this.filteredCities =null;
            this.subMerchantsLocationsArray = [];
          }, 500);
        })
      );

    }
  }

  cancel() {
    this.filteredStates = null;
    this.filteredCities = null;
    this.filteredBranches = null;
    this.resetForm();
    this.canChangeBranch = true;
  }

  resetForm() {
    this.branchForm.reset();
  }

  getState(stateName) {
    return this.locationService.getState(stateName).pipe(finalize(() => (this.isLoading = false)));
  }

  displayState(state?: any): any | undefined {
    return state ? state.state_name ? state.state_name : state : undefined;
  }

  displayCity(city?: any): any {
    return city ? city.city_name ? city.city_name.replace(/\b\w{3,}/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase())) : city : '';
  }

  selectState(state) {
    const control = this.branchForm.get('state_name');
    control.setValue(state.state_name);
  }

  setExistingBranch() {
    this.branchForm.get('existing_home_branch_id').setValue(this.existing_home_branch.existing_location.id);
  }

  selectCity(city) {
    this.sameBranch = false;
    const control = this.branchForm.get('city_name');
    control.setValue(city.city_name);
    const cityValue = { 'city_name': control.value };
    this.locationService.getState(cityValue).subscribe(res => {
      if (res.stateList[0]) {
        const stateControl = this.branchForm.get('state_name');
        let stateName = res.stateList[0].state_name
        stateControl.setValue(this.setState(stateName));
        stateControl.updateValueAndValidity();
      }
    }, err => {
      // console.log(err);
    }, () => {
      this.locationService.getMerchantLocations(this.branchForm.value).subscribe(this.checkMerchantLocationResponse);
    });

  }

  setState(stateName) {
    return stateName.replace(/\b\w{3,}/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()))
  }
  selectedBranch(branch) {
    this.sameBranch = false;
    this.sameBranch = this.isBranchSame(branch);
  }

  isBranchSame(newBranch: number): boolean {
    return this.branchForm.get('existing_home_branch_id').value === newBranch;
  }

  handlePendingResponse(result: SearchCustomer) {

    this.existing_home_branch = result.existing_home_branch;

    if (!Object.keys(result.existing_home_branch).length) {
      this.existing_home_branch = null;
    }
    if (this.existing_home_branch) {
      this.setExistingBranch();
      // this.branchForm.get('existing_home_branch_id').setValue(this.existing_home_branch.existing_location.id);
    }

    if (!Object.keys(result.home_branch_change_request).length) {
      this.home_branch_change_request = null;
      this.canChangeBranch = !this.canChangeBranch;
      return;
    }
    this.home_branch_change_request = result.home_branch_change_request;
  }

  submitRequest() {
    this.spinner.active = true;
    const cFrom = this.branchForm;

    const existingBranchControl = cFrom.get('existing_home_branch_id');
    const newBranchControl = cFrom.get('new_home_branch_id');

    const submitData = {
      customer_loyalty_id: this.user.customer_loyalty_id,
      mobile_number: this.user.mobile_number,
      request_type: 3
    };

    if (this.isBranchSame(newBranchControl.value)) {
      this.sameBranch = true;
      return;
    }
    cFrom.disable();

    const requestParam = {
      mobile_number: submitData.mobile_number,
      type: 203 // for chnage home branch
    };
    this.subscriptions.push(this.userService.requestOtp(requestParam)
      .subscribe(
        result => {
          this.spinner.active = false;
          if (!result.data) {
            cFrom.enable();
            return;
          }

          if (this.checkOtpResponse(result)) {
            cFrom.enable();
            newBranchControl.setErrors({ 'serverError': true, message: result.message });
            newBranchControl.markAsTouched();
            return;
          }
          const dialogData = {
            ...result.data,
            ...cFrom.getRawValue(),
            ...submitData,
            type: 203
          };
          this.callDialog(dialogData);
        },
        error => {
          console.log(error);
          cFrom.enable();
          this.spinner.active = false;
        }
      ));
  }

  checkOtpResponse(result: ApiResult) {
    return this.isServerError(result) || this.attemptExeed(result);
  }

  attemptExeed(result: ApiResult) {
    if (result.data['otp_attempt'] >= 3) {
      this.spinner.active = false;
      if (result.data['statusCode'] === 104) {
        this.otpAttempts = true;
      }
      this.setFormError(result);

      return true;
    }
    return false;
  }

  isServerError(result: ApiResult) {
    if (result.data['statusCode'] === 105) {
      this.spinner.active = false;
      this.setFormError(result);
      return true;
    }
    return false;
  }

  setFormError(result: ApiResult) {
    this.branchForm.setErrors({ 'server-error': true, message: result.message });
  }

  callDialog(data) {
    const cFrom = this.branchForm;
    const dialogRef = this.dialog.open(VerifyOptComponent, {
      autoFocus: true,
      disableClose: true,
      maxWidth: '380px',
      panelClass: 'verify-otp-panel',
      data: data
    });
    dialogRef.afterClosed().pipe(
      switchMap(result => {
        if (!result) {
          cFrom.enable();
          return of();
        } else {
          this.homeBranchChangeList.emit(false);
          return this.sharedService.changeRequestFromCustomer(result);
        }
      })
    ).subscribe(result => {
      let alertParams = {};
      if (!result) {
        cFrom.enable();
        return;
      } else {
        cFrom.enable();
        const errorStatusCode = [102, 104];
        if (errorStatusCode.indexOf(result.data['statusCode']) !== -1) {
          alertParams = { type: 'error', text: result.message };
          // this.setFormError(result);
        }

        if (result.data['statusCode'] === 100) {
          alertParams = { type: 'success', text: result.message }
        }
        this.setAlert(alertParams);
        setTimeout(() => {
          this.homeBranchChangeList.emit(true);
        }, 500);
        // this.cancel();
        this.checkInputSet();
        this.isRequested = true;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
