import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  getDefaultPage(){
    if(this.authService.loggedIn){
      const defaultPath = localStorage.getItem('defaultPath');
      this.router.navigateByUrl(defaultPath);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
