import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerifyEmailAddressService } from './verify-email-address.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-verify-email-address',
  templateUrl: './verify-email-address.component.html',
  styleUrls: ['./verify-email-address.component.scss']
})
export class VerifyEmailAddressComponent implements OnInit, OnDestroy {
  data: any;
  idObject: any;
  subscriptions: Subscription[] = [];
  message: string;
  success: boolean;

  constructor(
    private route: ActivatedRoute,
    private verifyEmailAddressService: VerifyEmailAddressService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'customer-class');
    
    this.data = this.route.snapshot.queryParams['q']

    try {
      var base64Url = this.data.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      this.idObject = JSON.parse(window.atob(base64));
      //verify email address
      let sendData = {
        "email_verify_key": this.idObject.email_verify_key,
        "mobile_number": this.idObject.mobile_number
      }

      this.subscriptions.push(this.verifyEmailAddressService.verifyEmailAddress(sendData).subscribe(
        result => {
          // console.log(result);
          this.success=true;
          this.message = "Email has been verified successfully";
        }, err => {
          this.message = "Email couldn't be verified";
          this.success=false;
          // console.log(err);
        }
      )
      );
    }
    catch (err) {
      this.message = "Email couldn't be verified";
      // console.log(err);
    }

  }



  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


}
